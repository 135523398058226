.toolbox-custom-stage-background {
  min-height: 840px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  @include media-breakpoint-down(xs) {
    min-height: 600px;
  }

  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
}

.toolbox-custom-stage-content {
  overflow: hidden;
  position: relative;
  min-height: 840px;

  @include media-breakpoint-down(xs) {
    min-height: 600px;
  }

  .container {
    min-height: 840px;

    @include media-breakpoint-down(xs) {
      min-height: 600px;
    }
  }

  .stage-row {
    height: 840px;

    @include media-breakpoint-down(xs) {
      height: 600px;
    }

    .btn {
      margin-top: rem-calc(30px);
    }
  }

  .additional-image {
    margin: 0;
    margin-bottom: 20px;
    width: 130px;
  }

  .stage-product-image-wrapper {
    pointer-events: all;
  }

  .product-image {
    padding-right: 0px;
    position: absolute;

    max-width: 950px;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));

    @include media-breakpoint-down(lg) {
      top: 60%;
      max-width: 810px;
    }

    @include media-breakpoint-down(md) {
      max-width: 90%;
    }

    @include media-breakpoint-down(xs) {
      top: 75%;
    }
  }

  .stage-padded-container {
    padding-top: 100px;

    @include media-breakpoint-down(md) {
      padding-top: 80px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 80px;
      padding-left: 8px;
    }
  }
}

.stage-title {
  .like-h1 {
    margin-bottom: 0.8rem;
  }
}

.stage-column {
  z-index:100;
}

.stage-column-product {
  pointer-events: none;
  min-height: 840px;
  max-width: 950px;

  @include media-breakpoint-down(xs) {
    min-height: 600px;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

.webgl-desktop-visibility {
  display: none;
}

#modal-stage {

  .modal-dialog {
    max-width: 1000px;

    .modal-header {
      padding-bottom: 0rem;
    }

    .modal-body {
      height: 100%;

      .webgl-product-title {
        float: right;

        @include media-breakpoint-down(xs) {
          float: left;
        }
      }

      .webgl-video {
        width: 100%;
      }
    }
  }
}
