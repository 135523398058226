.navbar.fixed-top {
  z-index: 200;
}

// This was copied from dachcom-digital/toolbox class ".toolbox-element-edit-button"
.toolbox-element-custom-title {
  position: relative;
  display: block;
  margin: 3px 0 0 0;
  padding: 4px;
  background: #7b7b7b;
  opacity: 0.8;
  @include transition(opacity .3s ease);
  height: 35px;

  &:before {
    content: attr(data-title);
    position: absolute;
    right: 0;
    padding: 4px 30px 0 0;
    text-transform: uppercase;
    font-family: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: #bfbfbf;
  }
}

.toolbox-element-custom-content {
  @include make-container;
  @include make-container-max-widths;
  margin: 1rem auto;
}
