@import "functions";
@import "variables/all";

@import "~bootstrap/scss/bootstrap";
@import "~select2/dist/css/select2.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.css";
@import "~hover.css/scss/options";
@import "~hover.css/scss/mixins";
@import "~hover.css/scss/hacks";
@import "~hover.css/scss/effects/border-transitions/underline-from-left";
@import "~hover.css/scss/effects/border-transitions/underline-from-center";
@import "~animate.css/animate.css";
@import "~slick-carousel/slick/slick";
@import "~lightgallery/src/sass/lightgallery";
@import "~flag-icon-css/css/flag-icon.css";

@import "~video.js";

@import "fonts";
@import "mixins/all";
@import "placeholders/all";
@import "icons/all";

@import "./layout/all";
@import "./module/all";
@import "./toolbox/all";

@import "./pages/all";

@import "../../../BrickBundle/Resources/sass/main";
