.badge {
  font-weight: $font-weight-medium;
  font-size: 0.75rem;

  &.badge-ternary {
    background-color: $badge-ternary-bg;
    color: $badge-ternary-color;
  }

  &.badge-category,
  &.badge-tag {
    color: $gray-600 !important;
    background-color: $gray-100;
    font-weight: $font-weight-medium;
  }

  &.badge-regular {
    font-weight: $font-weight-regular;
  }
}

/* stylelint-disable  scss/at-extend-no-missing-placeholder */
.badge-auto-primary,
.badge-auto-secondary,
.badge-auto-ternary,
.badge-auto-info,
.badge-auto-success,
.badge-auto-warning,
.badge-auto-danger {
  @extend .badge;

  display: inline-block;
  margin-right: 0.5rem;
}

.badge-auto-primary {
  @extend .badge-primary;
}

.badge-auto-secondary {
  @extend .badge-secondary;
}

.badge-auto-ternary {
  @extend .badge-ternary;
}

.badge-auto-info {
  @extend .badge-info;
}

.badge-auto-success {
  @extend .badge-success;
}

.badge-auto-warning {
  @extend .badge-warning;
}

.badge-auto-danger {
  @extend .badge-danger;
}

/* stylelint-enable  scss/at-extend-no-missing-placeholder */
