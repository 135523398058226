$swi-color-facebook: #3B5998;
$swi-color-linkedin: $swi-color-facebook;
$swi-color-twitter: #00ACED;
$swi-color-youtube: #DA0000;
$swi-color-whatsapp: #4FCE5D;

$swi-download: unicode(\e9a3);
$swi-mail: unicode(\e9ea);
$swi-location: unicode(\e9e7);
$swi-signpost: unicode(\e9e6);
$swi-clock: unicode(\e975);
$swi-phone: unicode(\ea1e);
$swi-user: unicode(\ea87);
$swi-locked: unicode(\e9e8);
$swi-unlocked: unicode(\ea83);
$swi-checkmark: unicode(\e954);
$swi-plus: unicode(\ea26);
$swi-cross: unicode(\e992);
$swi-output: unicode(\ea16);
$swi-file-document: unicode(\e9b1);
$swi-desktop: unicode(\e99e);
$swi-browser: unicode(\e944);
$swi-comments: unicode(\e98c);
$swi-crosshair: unicode(\e997);
$swi-eye: unicode(\ea95);
$swi-7d: unicode(\ea94);
$swi-6d: unicode(\ea92);
$swi-5d: unicode(\ea93);
$swi-ablation-1-3s: unicode(\ea99);
$swi-ablation-1-5s: unicode(\ea97);
$swi-ablation-2s: unicode(\ea96);
$swi-corpus: unicode(\ea98);
$swi-compact: unicode(\ea9b);
$swi-laserarm: unicode(\ea9d);
$swi-laser: unicode(\ea9a);
$swi-schwind-cam: unicode(\ea9f);
$swi-smartsurface: unicode(\eaa1);
$swi-premium: unicode(\ea81);
$swi-pulse-rate: unicode(\e908);
$swi-healing-process: unicode(\ea80);
$swi-speed: unicode(\ea75);
$swi-safety: unicode(\ea74);
$swi-economics: unicode(\ea5c);
$swi-load-more: unicode(\ea5d);
$swi-bullet-list: unicode(\e948);
$swi-share: unicode(\ea53);
$swi-share-filled: unicode(\ea52);
$swi-facebook: unicode(\e900);
$swi-twitter: unicode(\ea3d);
$swi-youtube: unicode(\e901);
$swi-linkedin: unicode(\ea3c);
$swi-whatsapp: unicode(\e910); //Changed from e909
$swi-google: unicode(\ea3a);
$swi-instagram: unicode(\ea39);
$swi-baidu: unicode(\ea3b);
$swi-qzone: unicode(\ea38);
$swi-sina-weibo: unicode(\ea37);
$swi-vk: unicode(\ea36);
$swi-wechat: unicode(\e902);
$swi-facebook-invert: unicode(\e904);
$swi-linkedin-invert: unicode(\e905);
$swi-twitter-invert: unicode(\e906);
$swi-whatsapp-invert: unicode(\e907);
$swi-globe: unicode(\e9c5);
$swi-search: unicode(\ea4b);
$swi-zoom-in: unicode(\ea9e);
$swi-zoom-out: unicode(\eaa0);
$swi-chevron-left: unicode(\e963);
$swi-chevron-right: unicode(\e966);
$swi-chevron-up: unicode(\e96f);
$swi-chevron-down: unicode(\e95a);
$swi-arrow-left-square: unicode(\e91f);
$swi-arrow-right-square: unicode(\e925);
$swi-arrow-up-square: unicode(\e92c);
$swi-arrow-down-square: unicode(\e916);
$swi-arrow-left-circle: unicode(\e919);
$swi-arrow-right-circle: unicode(\e921);
$swi-arrow-up-circle: unicode(\e928);
$swi-arrow-down-circle: unicode(\e914);
$swi-cross-circle: unicode(\e994);
$swi-plus-circle: unicode(\ea27);
$swi-minus-circle: unicode(\e9f5);
$swi-attachment: unicode(\e92e);
$swi-attachment-2: unicode(\e92f);
$swi-award-badge: unicode(\e933);
$swi-backspace: unicode(\e936);
$swi-bell: unicode(\e93c);
$swi-bookmark: unicode(\e941);
$swi-briefcase: unicode(\e943);
$swi-calendar: unicode(\e94c);
$swi-calendar-download: unicode(\e94d);
$swi-checkmark-square: unicode(\e958);
$swi-clipboard: unicode(\e972);
$swi-delete: unicode(\e99c);
$swi-duplicate: unicode(\e9a5);
$swi-equalizer: unicode(\e9ad);
$swi-flag: unicode(\e9b8);
$swi-folder-2: unicode(\e9bb);
$swi-grid: unicode(\e9c9);
$swi-grid-2: unicode(\e9c7);
$swi-hamburger: unicode(\e9ca);
$swi-heart: unicode(\e9d0);
$swi-keynote-presentation: unicode(\e9dc);
$swi-layers: unicode(\e9e1);
$swi-multiple-documents-files: unicode(\ea04);
$swi-new: unicode(\ea0e);
$swi-note-text: unicode(\ea12);
$swi-notebook: unicode(\ea10);
$swi-permalink: unicode(\ea1c);
$swi-pie-chart: unicode(\ea21);
$swi-play: unicode(\ea22);
$swi-refresh: unicode(\ea3f);
$swi-replay: unicode(\ea41);
$swi-reply: unicode(\ea42);
$swi-save-floppy: unicode(\ea49);
$swi-sort-down: unicode(\e9b3);
$swi-sort-up: unicode(\e9b2);
$swi-tag: unicode(\ea6e);
$swi-tag-2: unicode(\ea70);
$swi-trash: unicode(\ea7e);
$swi-collapse: unicode(\e981);
$swi-expand: unicode(\e9af);
$swi-decrease: unicode(\e99a);
$swi-enlarge: unicode(\e9a6);
$swi-zoom: unicode(\ea9c);
$swi-preferences: unicode(\ea2c);
$swi-movie-recorder: unicode(\ea03);
$swi-image-picture: unicode(\e9d6);
$swi-camera: unicode(\e94f);
$swi-print: unicode(\ea30);
$swi-microphone: unicode(\e9f1);
$swi-microphone-off: unicode(\e9f3);
$swi-volume: unicode(\ea8a);
$swi-volume-full: unicode(\ea8c);
$swi-volume-muted: unicode(\ea8f);
$swi-volume-off: unicode(\ea90);
$swi-info: unicode(\e903);
$swi-atos-anwenderfreundlich: unicode(\e909);
$swi-atos-eyetracking: unicode(\e90a);
$swi-atos-flapdurchmesser: unicode(\e90b);
$swi-atos-kontaktelement: unicode(\e90c);
$swi-atos-lentikelgeometrie: unicode(\e90d);
$swi-atos-zentrierung: unicode(\e90e);
$swi-atos-zufriedenheit: unicode(\e90f);
$swi-watch: unicode(\e911);
$swi-atos-icrs: unicode(\e912);
$swi-atos-lowdose: unicode(\e913);
$swi-foresight-demonstration: unicode(\e915);
$swi-foresight-feedback-double-check: unicode(\e917);
$swi-foresight-one-click: unicode(\e918);
$swi-foresight-training: unicode(\e91a);
