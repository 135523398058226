#company-create {


  .form-error-message,
  .form-success-message {
    display: none;
    &.show {
      display: block;
    }
  }


  input {
    @include placeholder {
      font-size: $p-xs-fontsize;
    }
  }

  .select2-selection__placeholder {
    font-size: $p-xs-fontsize;
  }

  .invalid-feedback {
    &.show {
      display: block;
    }
  }


  .form-control.hidden {
    display: none;
  }

  /* Fix issue with placeholder in additional distribution areas selection */
  .select2-search__field {
    width: 100% !important;
  }

  .in-form-mode-default,
  .in-form-mode-physician,
  .in-form-mode-representative {
    display: none;
  }

  &.form-mode-default {
    .in-form-mode-default {
      display: block;
    }
  }
  &.form-mode-physician {
    .in-form-mode-physician {
      display: block;
    }
  }
  &.form-mode-representative {
    .in-form-mode-representative {
      display: block;
    }
  }

  .company-create-form {
    margin: 0;

    &:not(.title) {
      margin-top: 5rem;
    }

    input {
      width: 100%;
    }

    .hint-col {

      /* TODO: Form / Hint Layout is derived from MyPage Editor form. Maybe extract common layouts */

      .mypage-hint-outter {
        background-color: $gray-100;
        margin-top: 15px;
        display: table;
        width: 100%;
        border-collapse: separate;
        border-spacing: 10px;

        .mypage-hint {
          display: table-row;
          &-large {
            height: 3.5rem;
          }
          .icon-column, .hint-text {
            display: table-cell;
          }
          .icon-column {
            vertical-align: middle;
          }
          .hint-text {
            vertical-align: middle;
            font-size: 0.65rem;
            line-height: 1.2;
          }

        }
      }
    }

    .content-col {
      margin: 1rem 0;
    }

    .content-col-sm {
      margin: 0.5rem 0;
    }



    .select2-selection--multiple {
      &::before,
      &::after {
        position: absolute;
        /* stylelint-disable */
        font-family: schwindicons !important;
        /* stylelint-enable */
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: .9rem;
        color: #3094b4;
        right: 10px;
      }

      &::before {
        top: 1rem;
        content: "\E96F";
      }

      &::after {
        top: 1.75rem;
        content: "\E95A";
      }
    }

    @include media-breakpoint-up(md) {
      .input-col {
        padding-left: 0;
        padding-right: 15px;
      }

      .hint-col {
        position: relative;

        &.top-1 {
          top: 1rem;
        }
        &.top-0-5n {
          top: -0.5rem;
        }
        &.top-1-5 {
          top: 1.5rem;
        }
        &.top-2 {
          top: 2rem;
        }
        &.top-3 {
          top: 3rem;
        }
      }
    }
  }


  .edit-distribution {
    .select2-selection__rendered {
      padding: 0.3rem 0;
      .select2-selection__choice {
        background-color: $secondary;
        color: $white;

        .select2-selection__choice__remove {
          color: $white;
          float: right;
          margin-left: 1.5rem;
          font-size: 1.5rem;
          line-height: 0.6;
          font-weight: normal;
        }
      }
    }
  }

  &.editor-locked {

    input, textarea, .form-control, .checkbox-switch-slider, .custom-checkbox {
      pointer-events:none !important;
      cursor: not-allowed !important;
    }
    .custom-checkbox {
      .custom-control-label:before {
        background-color:#F5F5F5;
      }
    }
    .select2-selection, .checkbox-switch-slider {
      background-color:#F5F5F5;
    }
    .fake-input-button {
      background-color: #AAA;
    }
    input, textarea, .fake-input, .select2 {
      pointer-events:none;
      color:#AAA;
      background-color:#F5F5F5;
      cursor: not-allowed !important;
    }
    button {
      pointer-events:none;
      cursor: not-allowed !important;
    }
  }

  .ajax-submit-indicator {
    display: none;
    &.show {
      display: inline;
    }
  }


}


