.toolbox-content.wysiwyg {
  ul,
  ol {
    padding-left: 2em;
    list-style: none;

    li {
      position: relative;

      &:before {
        position: absolute;
        display: block;
        top: 0;
        left: -0.5em;
        margin-left: -2em;
        min-width: 2em;
        text-align: right;
        color: $primary;
      }
    }
  }

  ul {
    li:before {
      content: '•';
    }
  }

  ol {
    counter-reset: ordered-list;

    li {
      counter-increment: ordered-list;

      &:before {
        content: counter(ordered-list)'.';
      }
    }
  }

  tr.light-gray-table-row {
    td {
      background-color: $light;
    }
  }

  tr.light-gray-table-row-small {
    td {
      background-color: $light;
      padding: 0;
      font-size: 0.75rem;
      font-weight: $font-weight-bold;
    }
  }

  /* stylelint-disable no-descending-specificity */
  table.time-table {
    border: none;
    width: 100%;

    tbody {
      border: none;

      td {
        border: none;
        padding: $space-1-2 $space-2 $space-1-2 $space-1-2;
      }
      tr + tr {
        td {
          border-top: 1px solid $gray-300;
        }
      }
      tr + tr.light-gray-table-row {
        td {
          border: none;
        }
      }
      tr.light-gray-table-row + tr{
        td {
          border-top: none;
        }
      }
      tr + tr.light-gray-table-row-small {
        td {
          border: none;
        }
      }
      tr.light-gray-table-row-small + tr{
        td {
          border-top: none;
        }
      }


      td:first-child {
        width: 1px;
        white-space: nowrap;
      }

      tr.light-gray-table-row-small {
         td {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: $space-1-2;
          padding-right: 0;
        }
      }

    }
  }

  table.table-normal {
    margin-top: 15px;
    margin-bottom: 15px;
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;

    thead {
      th {
        border: 1px solid #212529;
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;

        @media (max-width: 450px) {
          padding: 0.1rem 0.1rem 0.1rem 0.1rem;
        }
      }
    }

    tbody {
      tr:nth-child(odd) {background: #dfeef5}

      td {
        border: 1px solid #212529;
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;

        @media (max-width: 450px) {
          padding: 0.1rem 0.1rem 0.1rem 0.1rem;
        }
      }
    }
  }

  table.table-first-col-one-line {
    tbody {
      tr td:nth-child(1) {
        white-space: nowrap;
      }
    }
  }

  table.table-second-col-one-line {
    tbody {
      tr td:nth-child(1),
      tr td:nth-child(2) {
        white-space: nowrap;
      }
    }
  }

  .pimcore_editable {
    table.time-table {
      td {
        border-left: 1px dashed #cccccc;
        border-right: 1px dashed #cccccc;
      }
    }
  }
  /* stylelint-enable no-descending-specificity*/
}


.toolbox-column {
  .toolbox-content.wysiwyg {
    padding: 0 0;
  }
}

.pimcore_wysiwyg.mce-content-body {
  font-family: "DIN W01", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}
