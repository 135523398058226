.toolbox-custom-youtube-video {
  position: relative;

  iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: calc(100% + 1px);
  }

  .video-aspect {
    width: 100%;
    height: auto;
  }
}
