$header-height: 3.5rem;
$header-mobile-height: 4.5rem;
$header-logo-height: 2.5rem;
$header-logo-width: 11rem;
$header-logo-width-portal: 14rem;
$header-mobile-logo-width: 8rem;
$header-navbar-bg: $white;
$header-navbar-bg-dark: transparent;
$header-navbar-bg-dark-editmode: $darker-primary;
$header-navlink-color: $black;
$header-navlink-color-dark: $white;
$header-navbar-border-bottom: 1px solid $gray-300;
$header-navbar-fontsize: 1rem;
$header-dropdown-box-shadow: 0 2px 8px rgba($black, 0.25);
$header-dropdown-border: none;
$header-dropdown-item-bg: transparent;
$header-dropdown-item-color: $black;
$header-dropdown-item-active-bg: transparent;
$header-dropdown-item-active-color: $primary;
$header-search-input-bg: $gray-100;
$header-search-input-bg-dark: rgba($black, 0.2);
$header-search-input-color-dark: $white;
$header-search-input-border: none;
$header-search-input-border-radius: 0;

$megamenu-offset: 0.75rem;
$megamenu-title-margin: 0.75rem 0;
$megamenu-link-color: $black;
$megamenu-link-active-color: $primary;
$megamenu-showall-color: $darker-primary;
$megamenu-showall-bg: $gray-150;
