/*!
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers.
  You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software.
  Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/
@font-face {
  font-family: $font-family-base-name;
  font-weight: $font-weight-bold; //BOLD
  src: url('../fonts/5426014/a94d5d16-cbe3-439e-b1a5-fbdfb180d789.eot?#iefix');
  src: url('../fonts/5426014/a94d5d16-cbe3-439e-b1a5-fbdfb180d789.eot?#iefix') format('eot'),
       url('../fonts/5426014/8beea44e-c2c9-4889-9123-13d0e8b8d1bc.woff2') format('woff2'),
       url('../fonts/5426014/871e70ec-2f76-44c5-a2f1-77ee8cd0a5fe.woff') format('woff'),
       url('../fonts/5426014/8fa83ec7-0734-49e5-b5ad-b13a41463328.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-base-name;
  font-weight: $font-weight-medium; //MEDIUM
  src: url('../fonts/5426076/9f48a411-8295-4ad8-b97a-f4cc38cef43d.eot?#iefix');
  src: url('../fonts/5426076/9f48a411-8295-4ad8-b97a-f4cc38cef43d.eot?#iefix') format('eot'),
       url('../fonts/5426076/79949f65-d692-489f-bd6b-b62647297229.woff2') format('woff2'),
       url('../fonts/5426076/b9597dec-1d68-488e-87e0-d7824cf36028.woff') format('woff'),
       url('../fonts/5426076/964c9b01-5d07-4ef1-8b71-3b173b54fbcc.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-base-name;
  font-weight: $font-weight-regular; //REGULAR
  src: url('../fonts/5426089/a397c752-ee83-47cf-b8a8-b476511dd2c9.eot?#iefix');
  src: url('../fonts/5426089/a397c752-ee83-47cf-b8a8-b476511dd2c9.eot?#iefix') format('eot'),
       url('../fonts/5426089/53fb9c60-2054-4c31-9283-5562244b4935.woff2') format('woff2'),
       url('../fonts/5426089/45831ca8-93bd-4808-aa5f-3c915944f727.woff') format('woff'),
       url('../fonts/5426089/429a3e29-88da-4603-94ff-c1450c089c88.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-base-name;
  font-weight: $font-weight-light; //LIGHT
  src: url('../fonts/5426105/f2fdd2b7-b1c3-436b-9041-724c6a02057f.eot?#iefix');
  src: url('../fonts/5426105/f2fdd2b7-b1c3-436b-9041-724c6a02057f.eot?#iefix') format('eot'),
       url('../fonts/5426105/a5a426db-6dbd-4135-b607-371a197c38fa.woff2') format('woff2'),
       url('../fonts/5426105/d6062c05-ec0a-4cd8-b859-20c330d2785f.woff') format('woff'),
       url('../fonts/5426105/0a2435f1-0a73-40d5-a303-fc076009ab66.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-base-name;
  font-weight: $font-weight-thin; //THIN
  src: url('../fonts/5426183/4845b040-0dec-4716-b749-074d0a07a2ca.eot?#iefix');
  src: url('../fonts/5426183/4845b040-0dec-4716-b749-074d0a07a2ca.eot?#iefix') format('eot'),
       url('../fonts/5426183/8e438e9a-fc95-4b10-9e9c-9c259ff5815a.woff2') format('woff2'),
       url('../fonts/5426183/9ebee994-ea45-4eab-ac20-69cfe07c8b77.woff') format('woff'),
       url('../fonts/5426183/25765e85-b059-4665-ad48-403cf7e5e8c0.ttf') format('truetype');
}
