$white: #ffffff;
$gray-100: #F5F7F9;
$gray-150: #EDF4F7;
$gray-200: #eaedef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #777777;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;

$color-schwind-lightblue: #3094B4;
$color-schwind-blue: #006890;
$color-schwind-darkblue: #00506F;
$color-schwind-yellow: #C6AC00;
$color-schwind-green: #69923A;
$color-schwind-orange: #E05206;
$color-schwind-black: $black;
$color-schwind-white: $white;
$color-schwind-lightgray: $gray-100;
$color-schwind-darkgray: #888;

$blue: $color-schwind-lightblue;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: $color-schwind-orange;
$yellow: $color-schwind-yellow;
$green: $color-schwind-green;
$teal: #20c997;
$cyan: #17a2b8;


$primary: $color-schwind-lightblue;
$secondary: $gray-500;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $color-schwind-orange;
$light: $color-schwind-lightgray;
$dark: $gray-800;
$dark-primary: $color-schwind-blue;
$darker-primary: $color-schwind-darkblue;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light".
// Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

$container-primary-bg: $color-schwind-white;
$container-secondary-bg: $color-schwind-lightgray;
$color-separator: $gray-500;
