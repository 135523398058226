@use "sass:math";

$arrow-height: 1.75rem;
$animation-duration: 0.5s;

.product-overview-intro {
  margin-bottom: 3rem;
}

.product-category {
  margin-bottom: 4rem;

  .btn-arrow {
    margin-top: 1rem;

    &:after {
      @include transition(transform $animation-duration ease);
    }
  }

  .category-text-content {
    position: relative;
    padding-bottom: 2.5rem;

    .btn-open-category {
      position: absolute;
      bottom: 0;
      left: math.div($grid-gutter-width, 2);
      margin: 0;
    }
  }

  .category-slideout {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: $arrow-height;
    overflow: hidden;
    @include transition(height $animation-duration ease);

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      left: 50%;
      @include transform(translateX(-50%));
      top: $arrow-height;
      height: $arrow-height;
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;
      border-bottom: $arrow-height solid $gray-100;
      @include transition(all $animation-duration ease);
      opacity: 0;
    }

    .slideout-container {
      position: relative;
      background-color: $gray-100;
      width: 100%;
      height: 100%;
    }
  }

  .close-slideout {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 2.75rem;
    height: 2.75rem;
    color: $primary;
    font-size: 3rem;
    line-height: 2rem;
    text-align: center;
    font-weight: $font-weight-thin;
    cursor: pointer;
    z-index: 1;
  }

  .btn-slide {
    &.btn-prev {
      left: -1.75rem;
      @include transition(left $animation-duration ease);
    }

    &.btn-next {
      right: -1.75rem;
      @include transition(right $animation-duration ease);
    }
  }

  &.category-open {
    .category-slideout {
      &:before {
        opacity: 1;
        top: 0;
      }
    }

    .btn-arrow:after {
      @include transform(rotate(90deg));
    }

    .btn-slide {
      @include transition-delay($animation-duration);

      &.btn-prev {
        left: 0;
      }

      &.btn-next {
        right: 0;
      }
    }
  }
}

.product-slider {
  padding: 3rem 0;

  &.slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-list {
    @include make-container;
    @include make-container-max-widths;
    padding: 0;
  }

  .product-slide {
    margin: 0 1rem;
  }

  .product-slide-content {
    max-width: 400px;
    height: 100%;
    margin: 0 auto;
    background-color: $white;

    > a {
      text-decoration: inherit;
      color: inherit;
      outline: none;
    }
  }

  .product-slide-text {
    padding: 1rem;
  }

  .product-slide-teaser {
    margin-bottom: 0.25rem;
  }

  .product-slide-img {
    width: 100%;
    height: auto;
    max-width: none;
    max-height: none;
  }

  .product-slide-btn {
    align-self: start;
    position: absolute;
    bottom: 1rem;
  }
}

.container-related-products {
  padding: 3rem 0 3rem 0;

  .related-products-title {
    display: inline-block;
  }

  .show-all-products {
    float: right;
  }
}

.modal-brochure-select {
  margin: 1rem 0;

  .brochure-select-title {
    margin-bottom: 1rem;
  }
}

#show-product-modal {
  textarea {
    min-height: 12rem;
  }
}
