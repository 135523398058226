.vjs-big-play-button {
  background-image: url('../../img/play-button.svg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  border: none !important;
  height: 48px !important;
  width: 68px !important;
  opacity: 0.9 !important;
  background-color: rgba(0,0,0,0) !important;

  .vjs-icon-placeholder {
    &:before {
      content: "" !important;
    }
  }
}

.toolbox-custom-upload-video {
  .vjs-poster {
    background-color: white;
  }
}
