@use "sass:color";

.toolbox-custom-heroshot {
  margin-bottom: 4rem;
  background: $heroshot-bg radial-gradient(ellipse at center, color.adjust($heroshot-bg, $lightness: 5%) 0%, color.adjust($heroshot-bg, $lightness: 5%) 35%, $heroshot-bg 100%);

  .heroshot-badge {
    min-width: 100px;
    margin-bottom: 1.25rem;
  }

  .heroshot-row {
    margin: 0;
  }

  .heroshot-container {
    padding: $header-height 0 0 0;

    @include media-breakpoint-up(lg) {
      padding: 8rem 0 6rem 0;
    }

    #page.has-breadcrumbs & {
      padding: ($header-height + 4) 0 0 0;

      @include media-breakpoint-up(lg) {
        padding: 11rem 0 6rem 0;
      }
    }
  }

  .heroshot-title {
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  .heroshot-text {
    color: $white;
    font-size: 2.5rem;
  }

  a {
    margin-top: 1rem;
  }

  img {
    position: relative;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: -2rem;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      bottom: -6rem;
      right: 0;
      max-height: 130%;
      margin-top: 0;
    }
  }
}
