%grid-nogutter {
  > .row {
    margin-left: 0;
    margin-right: 0;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
