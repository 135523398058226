$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

$box-shadow-sm: none;
$box-shadow: none;
$box-shadow-lg: none;

$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$input-btn-focus-box-shadow: none;
$input-box-shadow: none;
$input-focus-box-shadow: none;

$btn-border-width: 0;
$input-btn-padding-y-sm: 0.15rem;
$input-btn-padding-x-sm: 0.75rem;
