.toolbox-custom-event-teaser-list {
  .event-teaser {
    .event-teaser-column {
      .event-teaser-data {
        margin: 0.5em 0;
      }

      .swi {
        padding-right: 0.25em;
      }
    }
  }
  &.homepage {
    .event-teaser-list-all-container {
      float: right;
      top: 50%;
      @include transform(translateY(-50%));
    }

    .event-teaser {
      margin: 0.5em 0;
      padding: 0.75em 0.25em;
      background-color: $color-schwind-lightgray;

      .event-teaser-data-item {
        padding-right: 1em;
      }

      .event-teaser-button {
        margin: 0.5em 0;
      }
    }
  }
  &.infocenter {
    background-color: $white;
    padding: 0 2.5em;

    .event-teaser-head {
      padding: 0;
    }

    .btn-news-category {
      margin: 2.5em 1em 0em 0;
    }

    .event-teaser {
      .event-teaser-column  {
        padding: 1.3em 0 2em 0;
      }

      .event-teaser-data-item {
        padding-right: 0.5em;
      }

      .event-teaser-button {
        margin: 0.75em 0 0.5em 0;
      }
    }

    .event-teaser + .event-teaser {
      border-top: 1px solid $color-separator;
    }
  }

  &.boilerplate {
    background-color: $color-schwind-lightgray;
    height: 100%;
    padding-bottom: 3rem;

    .boilerplate-title {
      padding-left: 15px;
      margin-top: 1rem;
    }

    .row {
      margin-left: 15px;
      margin-right: 15px;
    }

    .boilerplate-btn {
      margin-top: 1rem;
    }
  }
}



.homepage-news-teaser-title,
.event-teaser-list-title {
  font-weight: $font-weight-light;
  font-size: 1.625rem;
}





