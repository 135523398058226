.portal-register-form-container {
  background-color: $container-secondary-bg;
  padding-top: 1em;

  .register-inputs {

    .was-validated {
      .form-control:valid + .input-group-append {
        span {
          border-color: $green;
        }
      }
    }

    input#password {
      margin-bottom: 0;
    }


    button {
      display: block;
      margin-top: 1.5em;
      margin-bottom: 0.3em;
    }


  }
}
