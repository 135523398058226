@use "sass:math";

.toolbox-custom-product-areas {
  .product-areas {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .product-areas-container {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      > .row {
        margin-left: 0;
        margin-right: 0;
      }

      .product-area-intro,
      .product-area {
        @include make-container;
        @include make-container-max-widths;
        width: auto;
        padding: 0;
      }

      .product-areas-column {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .product-areas-column {
    padding-top: 3rem;
  }

  .product-area-row {
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    > .col-md-12 {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(sm) {
      margin-left: - math.div($grid-gutter-width, 2);
      margin-right: - math.div($grid-gutter-width, 2);

      > .col-md-12 {
        padding-left: math.div($grid-gutter-width, 2);
        padding-right: math.div($grid-gutter-width, 2);
      }
    }
  }

  .product-area-slider {
    background-color: $white;
    margin-top: 3rem;
    display: none;

    .close-slideout {
      position: absolute;
      right: 1rem;
      margin-top: 1rem;
      width: 2.75rem;
      height: 2.75rem;
      color: $primary;
      font-size: 3rem;
      line-height: 2rem;
      text-align: center;
      font-weight: $font-weight-thin;
      cursor: pointer;
      z-index: 1;
    }

    .product-slider {
      padding: 8rem 0;
    }

    .btn-slide {
      background-color: $color-schwind-lightgray;
    }

    &-mobile.is-shown {
      display: block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &-desktop.is-shown {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &-both.is-shown {
      display: block;
    }
  }

  .product-area {
    height: 200px;
    background-color: $white;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    .product-area-link {
      &:after {
        @include transition(transform 0.4s ease);
      }
    }

    &.is-expanded {
      .product-area-link {
        &:after {
          @include transform(rotate(90deg));
        }
      }

      &.opens-left,
      &.opens-right {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 4rem;
          bottom: -3rem;
          height: 1.75rem;
          border-left: 2rem solid transparent;
          border-right: 2rem solid transparent;
          border-bottom: 1.75rem solid $white;
        }
      }

      @include media-breakpoint-down(sm) {
        &.opens-right:before {
          bottom: -3rem;
        }
      }

      @include media-breakpoint-up(md) {
        &.opens-right:before {
          left: auto;
          right: 4rem;
          bottom: -3.5rem;
        }
      }

      @media only screen and (max-width: 991px) {
        &.opens-right:before {
          bottom: -3rem;
        }
      }

      @include media-breakpoint-up(lg) {
        &.opens-right:before {
          bottom: -3rem;
        }
      }
    }


    + .product-area-slider {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }

  .product-area-img {
    width: 100%;
  }

  .product-area-content {
    padding: 20px 20px 20px 0;

    @include media-breakpoint-down(md) {
      padding: 20px 20px 20px 20px;
    }
  }

  .product-area-title {
    font-size: 1.25rem;
  }

  .product-area-teaser-text {
    &,
    p {
      @extend %text-xs;
    }
  }
}
