#physician-search {
  .search-physician {
    .search-physician-form {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .location-input-group,
      .select2-container {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .select2-container {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  .btn.btn-load-more-physicians,
  .physician-list-error,
  .physician-list-empty,
  #physician-info-template,
  #physician-list-template {
    display: none;
  }

  #physician-map {
    width: 100%;
    height: 38rem;

    .physician-info {
      max-width: 22rem;

      @include media-breakpoint-down(sm) {
        .btn {
          margin-bottom: 1rem;
        }
      }
    }

    .gm-style-iw {
      padding: 1rem 0;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        & > div {
          width: 320px !important;
        }
      }
    }
  }

  #physician-list {
    $physician-gutter: 5px;

    display: none;
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width;

    .physician-entry {
      margin: 0 (-$physician-gutter) ($physician-gutter * 4) (-$physician-gutter);

      &:last-of-type {
        margin-bottom: 0;
      }

      .physician-column {
        padding: 0 $physician-gutter;
      }

      .physician-contact {
        display: block;
      }

      .contact-list-length-0 {
        .physician-contact {
          display: none;
        }
      }

      .contact-list-length-1 {
        .physician-contact:last-of-type {
          display: none;
        }
      }
    }

    .physician-content {
      height: 100%;
      padding: 1rem;
      background-color: $white;

      .physician-website {
        word-break: break-word;
      }

      .physician-buttons {

        @include media-breakpoint-down(md) {
          text-align: left !important;

          &.physician-button-email {
            margin-top: 10px;
          }
        }
      }
    }

    .physician-logo-wrapper {
      text-align: center;
    }

    .physician-logo {
      text-align: center;
      max-width: 100%;
    }
  }

  .load-more-physicians-wrapper {
    display: none;
    text-align: center;
    padding-bottom: $grid-gutter-width;

    .loading-indicator {
      display: none;
      padding: 0.1rem 0 0 0;
    }

    .btn-load-more-physicians {
      margin: 0 auto;
    }
  }

  .physician-boilerplate {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
