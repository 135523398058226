.custom-news-list-components {
  .news-list-entry {
    background-color: white;
  }
}

.homepage-teaser-list {
  .homepage-news-teaser-all-container {
    float: right;
    top: 50%;
    @include transform(translateY(-50%));

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  .btn-news-category,
  .badge-date,
  .swi-chevron-right {
    display: none;
  }

  .news-text-column-content,
  .news-image-column + .news-text-column .news-text-column-content,
  {
    padding-top: 0;
  }
}
