.btn-contact-send {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-hint {
  position: relative;

  box-sizing: border-box;
  height: 38px;
  margin-top: 30px;
  padding: 5px;
  padding-right: 20px;

  background-color: $gray-100;

  i {
    position: absolute;
    top: 50%;
    left: 8px;
  }

  .hint-text {
    position: absolute;
    top: 50%;
    left: 32px;
    @include transform(translateY(-50%));

    box-sizing: border-box;
    display: inline-block;
    font-size: 0.65rem;
    line-height: 1.2;
  }
}

.mandatory-hint {
  margin-top: 20px;
  float: right;
}

.contact-form {
  textarea {
    height: 200px;
  }
}
