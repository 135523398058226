.toolbox-custom-event-list {
  margin-top: 2.5rem;

  .event-list-infobox {
    &:nth-child(odd) > .event-list-infobox-content {
      background-color: $light;
    }

    &:nth-child(even) > .event-list-infobox-content {
      background-color: rgba($light, 0.4);
    }
  }

  .event-list-date {
    border-bottom: 1px solid $primary;
    padding: 0.5rem 0;
    margin: 0;
  }

  .event-list-time {
    padding-top: 0.75rem;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding-left: 1rem;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  .event-list-desc {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    p {
      margin-bottom: 0.5rem;
    }
  }
}
