.video-js {
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    background-color: #00506F;
    border-color: #00506F;
    color: #000000;
    width: 98px !important;
    height: 69px !important;
    margin-top: calc(-69px / 2) !important;
    margin-left: calc(-98px / 2) !important;
  }
}
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border-color: $color-schwind-lightblue;
  background-color: $color-schwind-lightblue;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -ms-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}
