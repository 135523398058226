.toolbox-spacer {

  .spacer-6 {
    height: 6rem;
  }
  .spacer-8 {
    height: 8rem;
  }
  .spacer-10 {
    height: 10rem;
  }
  .spacer-12 {
    height: 12rem;
  }
}




.mt-space-1-2 {
  margin-top: $space-1-2;
}
.mt-space-1 {
  margin-top: $space-1;
}
.mt-space-2 {
  margin-top: $space-2;
}
.mt-space-3 {
  margin-top: $space-3;
}
.mt-space-4 {
  margin-top: $space-4;
}

.mb-space-0 {
  margin-bottom: $space-0;
}
.mb-space-1-2 {
  margin-bottom: $space-1-2;
}
.mb-space-1 {
  margin-bottom: $space-1;
}
.mb-space-2 {
  margin-bottom: $space-2;
}
.mb-space-3 {
  margin-bottom: $space-3;
}
.mb-space-4 {
  margin-bottom: $space-4;
}

.ml-space-1-2 {
  margin-left: $space-1-2;
}
.ml-space-1 {
  margin-left: $space-1;
}
.ml-space-2 {
  margin-left: $space-2;
}
.ml-space-3 {
  margin-left: $space-3;
}
.ml-space-4 {
  margin-left: $space-4;
}

.mr-space-1-2 {
  margin-right: $space-1-2;
}
.mr-space-1 {
  margin-right: $space-1;
}
.mr-space-2 {
  margin-right: $space-2;
}
.mr-space-3 {
  margin-right: $space-3;
}
.mr-space-4 {
  margin-right: $space-4;
}



.padding-space-1-2 {
  padding: $space-1-2;
}
.padding-space-1 {
  padding: $space-1;
}
.padding-space-2 {
  padding: $space-2;
}
.padding-space-3 {
  padding: $space-3;
}
.padding-space-4 {
  padding: $space-4;
}


.pt-space-1-2 {
  padding-top: $space-1-2;
}
.pt-space-1 {
  padding-top: $space-1;
}
.pt-space-2 {
  padding-top: $space-2;
}
.pt-space-3 {
  padding-top: $space-3;
}
.pt-space-4 {
  padding-top: $space-4;
}

.pb-space-1-2 {
  padding-bottom: $space-1-2;
}
.pb-space-1 {
  padding-bottom: $space-1;
}
.pb-space-2 {
  padding-bottom: $space-2;
}
.pb-space-3 {
  padding-bottom: $space-3;
}
.pb-space-4 {
  padding-bottom: $space-4;
}

.pl-space-1-2 {
  padding-left: $space-1-2;
}
.pl-space-1 {
  padding-left: $space-1;
}
.pl-space-2 {
  padding-left: $space-2;
}
.pl-space-3 {
  padding-left: $space-3;
}
.pl-space-4 {
  padding-left: $space-4;
}

.pr-space-1-2 {
  padding-right: $space-1-2;
}
.pr-space-1 {
  padding-right: $space-1;
}
.pr-space-2 {
  padding-right: $space-2;
}
.pr-space-3 {
  padding-right: $space-3;
}
.pr-space-4 {
  padding-right: $space-4;
}
