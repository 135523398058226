.toolbox-custom-background-brick {

  &.white-bg {
    background-color: $container-primary-bg;
  }

  &.gray-bg {
    background-color: $container-secondary-bg;
  }

  &.fullheight {
    height: 100%;
  }

  &.space-none {
    padding: 0;
  }

  &.space-1-4 {
    padding: $space-1-4;
  }

  &.space-1-2 {
    padding: $space-1-2;
  }

  &.space-3-4 {
    padding: $space-3-4;
  }

  &.space-1 {
    padding: $space-1;
  }

  &.space-2 {
    padding: $space-2;
  }

  &.space-4 {
    padding: $space-4;
  }

  &.space-6 {
    padding: $space-6;
  }

  &.space-8 {
    padding: $space-8;
  }

  &.space-10 {
    padding: $space-10;
  }

  &.space-12 {
    padding: $space-12;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;

    &.fullheight {
      height: auto;
    }
  }


}

body[data-noedit] {
  .toolbox-custom-background-brick {

    &.display-xs-down {
      @include media-breakpoint-up(sm) {
        display: none !important;
      }
    }

    &.display-sm-down {
      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }

    &.display-md-down {
      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }

    &.display-lg-down {
      @include media-breakpoint-up(xl) {
        display: none !important;
      }
    }

    &.display-sm-up {
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }

    &.display-md-up {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    &.display-lg-up {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    &.display-xl-up {
      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }

    &.display-never {
      display: none !important;
    }
  }
}
