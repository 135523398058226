.toolbox-custom-checklist {
  .checklist-entry {
    position: relative;
    padding-left: 3rem;

    &:before {
      @include swi-icon;
      content: $swi-checkmark;
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      font-size: 1.5rem;
      color: $color-schwind-blue;
    }
  }
}
