.gray-social-media-box {
  height: 100%;
  width: 100%;
  padding: 2rem;

  .grey-networking-area-headline {
    @extend %text-lg;
    color: $color-schwind-blue;
    font-weight: $font-weight-regular;
  }

  .btn-primary {
    margin-top: 2rem;
  }

  .social-media-icons {
    padding-top: 1rem;

    .btn {
      padding: 0;

      + .btn {
        margin-left: 0.625rem;
      }
    }
  }

  .btn-social-link:before {
    content: none;
  }

  .social-button {
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 2rem;
    line-height: 2rem;
  }
}

.contacts-sidebar {
  .gray-social-media-box {
    padding: 1.5rem 1rem;
    .grey-networking-area-headline {
      @extend %text-sm;
      font-weight: $font-weight-medium;
      color: $color-schwind-blue;
    }
    /* stylelint-disable no-descending-specificity */
    .btn {
      margin: 0;

      &.gray-networking-area-btn {
        margin-top: 2rem;
      }
    }
    /* stylelint-enable no-descending-specificity */
  }
}
