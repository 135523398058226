.pressreleases-list-entries {
  .pressreleases-list-entry {
    border-top: 1px solid $color-separator;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .badge {
      font-weight: $font-weight-regular;
      padding-left: 0;
      margin-right: 1rem;
    }
  }

  .pressreleases-button-column {
    text-align: right;
  }

  .pressreleases-button-wrapper {
    display: inline-block;
    top: 50%;
    @include transform(translateY(-50%));

    a {
      position: absolute;
      right: 0;
    }
  }

  .pressrelease-title {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  .infotext {
    margin-bottom: 1rem;
  }
}

.pressrelease-detail {
  h1 {
    margin-top: 2rem;
  }
}

.pressreleases-page-container {
  .pressreleases-list-entries {
    margin-top: 4rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  #pressreleases-load-more-list {
    @include media-breakpoint-up(lg) {
      padding-left: 4rem;
    }
  }
}
