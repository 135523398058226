@use "sass:math";

#statements {
  .social-sharing {
    .sharing-link {
      text-decoration: none;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }

      .swi:before {
        @include transition(color 0.2s ease);
      }

      &:hover,
      &:active {
        .swi:before {
          color: $gray-600;
        }
      }
    }
  }

  .statement-filter-buttons {
    margin: 2rem 0;

    .statement-filter {
      cursor: pointer;
      outline: none;
      background-color: transparent;
      color: $gray-500;
      border: 2px solid transparent;
      border-radius: 2px;

      &.filter-active {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  .statement-entry {
    margin-bottom: 1rem;
    cursor: pointer;
    opacity: 0;
    @include transition(all 0.4s ease-in);
    display: none;

    &.showing {
      display: block;
    }

    &.show {
      display: block;
      opacity: 1;
    }

    .statement-entry-content {
      border: 1px solid $gray-400;

      @media  (max-width: 991px) and (min-width: 768px) {
        .col-custom-statements-30 {
          flex: 0 0 30%;
          max-width: 30%;
        }
        .col-custom-statements-70 {
          flex: 0 0 70%;
          max-width: 70%;
        }
      }
    }

    .statement-image {
      position: relative;
      max-height: 100%;
      max-width: 100%;
    }

    .statement-text {
      padding: 0.5rem 0.5rem 0.5rem 0;
    }
  }

  .statement-modal {
    cursor: default;

    .statement-contact {
      padding-left: math.div($grid-gutter-width, 2);
      padding-right: math.div($grid-gutter-width, 2);
    }

    .statement-text {
      padding: 0;
    }

    .statement-column {
      padding: 0;

      .statement-text {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
      }
    }

    .modal-header {
      padding-bottom: 0;

      .close {
        margin-top: -3rem;
      }
    }

    .modal-text {
      margin: 1.5rem 0;
      margin-top: 0;
    }


    .statement-website {
      display: inline-block;
      margin-bottom: 0.2rem;
    }

    .statement-image {
      top: 0;
      @include transform(none);
      margin-bottom: 1.5rem;
    }
  }

  .video-statements-list {
    .video-statement {
      border: 1px solid $gray-400;
      margin: 0 0 1rem 0;

      @include media-breakpoint-down(md) {
        margin: 0 0 2rem 0;
      }
    }

    .video-content {
      padding: 0;
    }

    .video-text {
      position: relative;
      padding: 1rem 1.5rem 1.4rem 1.5rem;

      .statement-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3rem;
        padding: 0.8rem 1.5rem 0rem 1.5rem;
      }

      .statement-badge {
        vertical-align: middle;
      }

      .social-sharing {
        float: right;
      }
    }

    .pimcore_tag_video {
      width: 100%;
      height: 100%;

      iframe {
        display: block;
        height: 56.25%;
        min-height: 206px;

        @include media-breakpoint-down (md) {
          min-height: 386px;
        }

        @include media-breakpoint-down (sm) {
          min-height: 285px;
        }

        @include media-breakpoint-down (xs) {
          min-height: 217px;
        }
      }
    }
  }

  .loading-video-statements {
    display: none;
  }
}
