.footer {
  position: relative;
  overflow: hidden;
  background-image: url('../../img/footer-background.png');
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    background-image: url('../../img/footer-image.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .nav-item {
    padding-left: 0;
  }

  .nav-link {
    color: $footer-color;
    font-size: 0.75rem;
    line-height: 1.375rem;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      color: $footer-light-link-color-active;
    }
  }

  .footer-content {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    padding-left: 15px;
    display: flex;

    @include media-breakpoint-down(xs) {
      padding-left: 30px;
    }
  }

  .footer-spacer {
    height: 3rem;
  }

  .footer-column-links {
    margin-bottom: 1.5rem;

    .footer-column-title {
      color: $footer-color;
      font-size: 1rem;
      font-weight: $font-weight-medium;
      margin-bottom: 0.3rem;
    }
  }

}

.footer-navbar {
  @include make-container;
  @include make-container-max-widths;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;

  &.navbar-expand {
    flex-flow: row wrap;
  }

  .footer-copyright {
    min-width: 100px;
    padding: .5rem 3rem .5rem 0;

    .nav-link {
      padding: 0;

      &:hover {
        color: $white;
      }
    }
  }

  .navbar-nav {
    li.nav-item,
    li.nav-link {
      @include translate(0, 20%);
    }
  }

  .newsletter-item {
    margin: 0.4rem 0.5rem;
    margin-left: 0;
  }


  .social-item {
    margin-left: 1rem;

    @media (max-width: 1100px) {
      margin-left: 0.1rem;
    }

    @include media-breakpoint-down(md) {
      margin-left: 1rem;
    }

    .swi {
      color: $footer-social-icon-color;
      font-size: 1.5rem;
      line-height: 1.9;
    }
  }
}

.footer-left {
  padding-left: 0;

  .left-col:nth-child(4) {
    padding-right: 0;
    max-width: 18%;
  }

  .left-col:nth-child(5) {
    padding-left: 0;
  }
}

.footer-right {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .navbar-right {
    @include translate(0, 10%);
  }
}

body[data-edit] .footer-navbar .newsletter-item {
  margin-right: 2rem;
}

.mobile-visibility {
  display: none;
}

@include media-breakpoint-down(md) {

  .footer-content {
    display: block !important;
  }

  .desktop-visibility {
    display: none;
  }

  .mobile-visibility {
    display: block;
  }

  .left-row {
    display: block;
  }

  .footer-left {
    padding-left: 0;

    .left-col:nth-child(4) {
      padding-right: 15px;
    }

    .left-col:nth-child(5) {
      padding-left: 15px;
    }
  }

  .footer-right {

    .footer-navbar {
      position: relative;

      .nav-item {
        overflow: visible;
      }

      .nav-link {
        width: auto;
      }

      &.navbar-right {
        margin-top: 1rem;
        @include translate(0, 0);
      }
    }
  }

  .footer-copyright {
    padding: .5rem 2rem .5rem 0 !important;
  }

  .footer-spacer {
    display: block;
    height: 2rem !important;

    &.spacer-left {
      display: none;
    }
  }

  // IE 10 and up
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .footer-content {
      .col-sm-12,
      .col-md {
        padding-right: 106px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-content {
    padding-left: 30px;
  }

  .footer-right {

    .mr-auto {
      flex-direction: column;
    }

    li.nav-link {
      display: none;
    }

    a.nav-link {
      padding: 0 !important;
    }
  }
}
