.toolbox-container {
  &.no-gaps {
    .container,
    .container-fluid {
      padding: 0;
    }
  }

  .container-inner {
    overflow: hidden;
  }
}
