.toolbox-image-fullwidth {
  img {
    width: 100%;
  }

  > .row {
    > .col-12 {
      padding: 0;
    }
  }
}
