.input-group {
  .input-group-text {
    background-color: $input-bg;
  }
  &.with-icon {
    input {
      border-right: none;
    }

    .input-group-text {
      padding: 0 0.75rem;
      border: 1px solid $input-border-color;
      height: 100%;
      @include transition($input-transition);
    }

    input:focus + .input-group-append .input-group-text {
      border: 1px solid $input-focus-border-color;
    }
  }
}

.form-parent {
  position: relative;

  .hidden {
    display: none;
  }

  .title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 3rem;
  }

  label[for=formbuilder_6_profile_image],
  label[for=formbuilder_6_old_password],
  label[for=formbuilder_6_new_password],
  label[for=formbuilder_6_confirm_new_password] {
    display: none;
  }

  .profile-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  #profile-image {
    display: none;
  }
}

.was-validated {
  &.force-invalid-marking {
    .form-control.is-invalid {
      border: 1px solid $danger !important;
    }
  }

  .input-group.with-icon  {
    .is-invalid {
        +.input-group-append {
          .input-group-text {
            border: 1px solid $danger;
          }
        }
    }
  }
}

form.formbuilder.fieldset-label-h4 {
  legend.col-form-label{
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    color: #000000;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.formbuilder-row {
  .formbuilder-container-fieldset.fieldset-form-group-subdiv-no-row-margin fieldset.form-group div.row {
    margin-right: 0;
    margin-left: 2px;
  }
}

.form-group .form-group {
  margin-bottom: 0;
}
