.newsletter-listing {
  .newsletter-col {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .newsletter-entry {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .newsletter-value-date,
  .newsletter-value-title {
    display: inline-block;
  }

  .newsletter-value-date {
    margin-right: 1rem;
  }

  .newsletter-value-title {
    font-weight: $font-weight-medium;
  }

  .btn {
    margin-top: 0.5rem;
  }

  @include media-breakpoint-up(sm) {
    .newsletter-entry {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .btn {
      margin-top: 0;
    }
  }

  .newsletter-entry + .newsletter-entry {
    border-top: 1px solid $color-separator;
  }
}
