.account-forms-feeback-area {
  display: table;
  width: 100%;
  .feedback-area-inner {
    display: table-row;
  }
  background-color: $container-secondary-bg;
  .feedback-column {
    display:table-cell;
    vertical-align: top;
  }
  .icon-column {
    padding: 1.5rem 0.5rem 1.5rem 1.5rem;
  }
  .contact-icon {
    font-size: 3rem;
    color: $color-schwind-blue;
    line-height: 1;
  }
  .button-column {
    width: 100%;
    padding: 1.5rem 1.5rem 1.5rem 0.5rem;
  }
}