.alert.flash-success {
  text-align: center;
  background-color: #e1e9d8;
  border-color: #d5e0c8;
  color: #374c1e;
  margin: 2rem auto;
  max-width: 1108px;

}

form.members_user_change_password,
form.members_user_resetting_reset {
    .invalid-feedback.d-block {
      display: none !important;
    }
}


.lock-emailprimary-field {
  .lockable-emailprimary-field {
    pointer-events:none  !important;
    color: #495057  !important;
    background:#F5F5F5 !important;
  }
}
