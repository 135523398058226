.toolbox-gallery {
  .row {
    margin-right:0;
    margin-left: 0;
  }

  .slick-slider-boxes {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;

    li {
      width: 25%;
      padding: 15px;

      IMG {
        width: 100%;
        height: auto;
      }
    }

    li.cols100 {
      width: 100%;
    }

    li.cols50 {
      width: 50%;
    }
    li.cols33 {
      width: 33.33%;
    }
    li.cols25 {
      width: 25%;
    }
  }
}
