
.search {
  background-color: $container-secondary-bg;

  .search-headline {
    padding-top: 4em;
  }

  .search-input {
    width: 75%;
    margin-top: 2em;
    padding-bottom: 2em;

    .swi {
      padding-right: 0.5em;
    }

    .btn {
      padding: 0.5em 1.5em 0.5em 1em;
    }
  }
}

.lucene-search-results {
  min-height: 100px;
  background-color: $container-primary-bg;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.lucene-search-results-inner {
  .search-results-headline {
    padding-bottom: 2em;

    .search-results-headline-text {
      color: $primary;
      font-weight: $font-weight-medium;
    }

    .search-results-headline-result-count {
      color: $secondary;
    }
  }

  .highlight {
    background-color: #d1ecf1;
  }

  .lucene-search-result-list {
    .did-you-mean {
      margin: 3rem 0;
    }
  }

  .search-result {
    padding-bottom: 2rem;

    .search-result-headline {
      font-weight: $font-weight-bold;
    }

    .search-result-text {
      .alert {
        padding: 0;
        border: none;
      }
    }
  }

  .paginator {
    padding-top: 1rem;
    padding-bottom: 8rem;

    a {
      color: $secondary;
      margin-right: 1rem;
      padding: 0 0.6rem 0.2rem 0.6rem;

      &.active,
      &.next,
      &.previous {
        color: $primary;
      }

      &.active {
        border: 2px solid $primary;
        cursor: default;

        &:before {
          background: none;
        }
      }
    }
  }

  .content-elements {
    padding-top: 2em;
  }
}
