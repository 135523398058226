$space-0: 0;
$space-1-4: 0.25rem;
$space-1-2: 0.5rem;
$space-3-4: 0.75rem;
$space-1: 1rem;
$space-2: 2rem;
$space-3: 3rem;
$space-4: 4rem;
$space-6: 6rem;
$space-8: 8rem;
$space-10: 10rem;
$space-12: 12rem;
