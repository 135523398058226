.simplemaps-map-wrapper {
  margin: 5rem auto;
  padding: 3rem 0;
  border: 1px solid $gray-400;
  background-color: $gray-150;
}

.simplemaps-map {
  @include make-container;
  @include make-container-max-widths;
  padding: 0;

  [class^="sm_state_"].selected {
    fill: $darker-primary;
  }
}

.tt_sm {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid $gray-400 !important;
}
