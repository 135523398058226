.social-media-bar {
  display: flex;

  .flex-left {
    flex: 1 1 auto;
  }

  .flex-right {
    flex: 0 0 auto;
    transform: translateY(-20%);
  }

  .social-media-bar-text {
    display: inline;
    margin-left: 1em;
  }

  .social-media-list {
    display: inline;
    list-style-type: none;
    font-size: 1.5em;
    padding: 0;

    .social-media-list-item {
      display: inline;
      padding-left: 0.8rem;

      a {
        text-decoration: none;
        font-size: 1.2rem;
      }
    }
  }
}
