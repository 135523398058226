.devices {
  padding: 1rem 0 5rem 0;

  .row {
    margin-left: -30px;
    margin-right: -30px;
  }

  .col {
    margin: 15px 0;
  }

  .btn-prev {
    left: -14px !important;
  }

  .btn-next {
    right: -14px !important;
  }

  .device-content {
    border: 1px solid $gray-400;
    background-color: $white;
    height: 100%;
    padding-top: 1rem;

    > a {
      text-decoration: inherit;
      color: inherit;
      outline: none;
    }
  }

  .devices-title {
    margin-bottom: 2rem;

    .title {
      color: $primary;
    }
  }

  .device-text {
    padding-left: 1rem;

    .description {
      margin-top: 1.5rem;
    }

    .value {
      margin-bottom: 1rem;
    }
  }
}
