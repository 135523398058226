.toolbox-element.toolbox-linklist {
  ul {
    padding-left: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    li {
      color: $primary;
    }
  }
}
