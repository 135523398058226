body[data-edit] {
  .toolbox-protected-area-brick-editmode {
    border: 1px solid $color-schwind-orange;
  }
  .toolbox-protected-area-brick-editmode-title {
    background-color: $color-schwind-orange;
    color: white;
    padding: 0.2rem;
  }
}
