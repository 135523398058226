.toolbox-custom-stage-slider {
  .slick-arrow {
    display:none !important;
  }

  &:hover {
    .slick-arrow {
      display:block !important;
    }
  }
}

.toolbox-custom-stage-slide {
  position: relative;
  min-height: 600px;

  @include media-breakpoint-up(md) {
    min-height: 650px;
  }

  .container {
    min-height: 600px;

    @include media-breakpoint-up(md) {
      min-height: 650px;
    }
  }

  .slide-row {
    height: 600px;

    @include media-breakpoint-up(lg) {
      height: 650px;

      .btn {
        margin-top: rem-calc(30px);
      }
    }

  }

  .additional-image {
    margin: 0;
    margin-bottom: 20px;
    width: 130px;
  }

  .no-image .additional-image {
    margin: 0 auto 20px;
  }

  .stage-product-image-wrapper {
    pointer-events: all;
  }

  .product-image {
    padding-right: 0px;

    width: 90%;
    @include media-breakpoint-up(sm) {
      padding-right: 10px;
      width: 65%;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      position: absolute;
      left: 0;
    }
    bottom: 0;
  }

  .slide-padded-container {
    padding-top: 100px;

    @include media-breakpoint-down(md) {
      padding-top: 80px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 50px;
      padding-left: 8px;
    }
  }

  .no-image {
    text-align: center;

    .slide-padded-container {
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }
  }
}

.slide-column {
  z-index:100;
}

.slide-title {
  .like-h1, h1 {
    margin-bottom: 0.8rem;
  }
}

.slide-white-bottom {
  background-color: $white;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 90;
}

.slide-column-product {
  pointer-events: none;

  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 0;
    max-width: 850px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 650px;
  }
}

.blur-overlay {
    background-color: black;
    opacity: 1;
    min-height: 600px;

    @include media-breakpoint-up(md) {
      min-height: 650px;
    }

    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.blur-text, .blur-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.blur-circle {
  width: 222px;
  height: 222px;
}

.blur-text {
  color: $white;
  font-size: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }

  span {
    opacity: 0;
  }
}

.toolbox-custom-stage-slide-background {
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
  min-height: 600px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-height: 650px;
  }

  &.panther {
    @include media-breakpoint-down(md) {
      background-position: 78% 50%;
    }
  }

  &.walhai {
    @include media-breakpoint-down(md) {
      background-position: 34% 50%;
    }
  }

  &.seepferd {
    @include media-breakpoint-down(md) {
      background-position: 66% 50%;
    }
  }

  &.eule {
    @include media-breakpoint-down(md) {
      background-position: 78% 50%;
    }

    ~ .blur-text {
      color: $color-schwind-blue;
    }
  }
}
