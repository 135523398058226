.small-gutters,
.toolbox-columns-small-gutters > .row {
  margin-right: -8px;
  margin-left: -8px;

  > .col,
  > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.toolbox-columns-background-inner-primary {
  background: $container-primary-bg;
}

.toolbox-columns-background-inner-secondary {
  background: $container-secondary-bg;
}

.col-5ths,
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  @include make-col-ready;
}

.col-xs-5ths {
  @include make-col(1, 5);
}

@include media-breakpoint-up(sm) {
  .col-sm-5ths {
    @include make-col(1, 5);
  }
}

@include media-breakpoint-up(md) {
  .col-md-5ths {
    @include make-col(1, 5);
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-5ths {
    @include make-col(1, 5);
  }
}

.col-xs-2-5ths {
  @include make-col(2, 5);
}

@include media-breakpoint-up(sm) {
  .col-sm-2-5ths {
    @include make-col(2, 5);
  }
}

@include media-breakpoint-up(md) {
  .col-md-2-5ths {
    @include make-col(2, 5);
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-2-5ths {
    @include make-col(2, 5);
  }
}

.col-xs-3-5ths {
  @include make-col(3, 5);
}

@include media-breakpoint-up(sm) {
  .col-sm-3-5ths {
    @include make-col(3, 5);
  }
}

@include media-breakpoint-up(md) {
  .col-md-3-5ths {
    @include make-col(3, 5);
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-3-5ths {
    @include make-col(3, 5);
  }
}

.col-xs-4-5ths {
  @include make-col(4, 5);
}

@include media-breakpoint-up(sm) {
  .col-sm-4-5ths {
    @include make-col(4, 5);
  }
}

@include media-breakpoint-up(md) {
  .col-md-4-5ths {
    @include make-col(4, 5);
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-4-5ths {
    @include make-col(4, 5);
  }
}

.row-vertical-center {
  display: flex;
  align-items: center;
}

