.publications-page-container,
.studies-page-container {
  .extended-container-padding {
    @include media-breakpoint-up(lg) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .ajax-change-indicator {
    padding-bottom: 1rem;
  }

  .publication-item {
    background-color: $white;
    padding: 1rem;

    img {
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }

  .publication-item + .publication-item {
    margin-top: 1.5rem;
  }

  .study-item {
    margin-top: 2rem;
    margin-bottom: 2rem;

    img {
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }

  .publications-selections {
    margin-bottom: 2rem;

    .select2 {
      width: 100% !important;
      margin: 0 0 1rem 0;
      border-collapse: separate;

    }
  }
}

.study-detail {
  .study-meta-info {
    margin-bottom: 1rem;
  }

  .study-teaser-text {
    margin-bottom: 3rem;
  }
}

.study-persons {
  .persons-headline {
    margin-bottom: 1rem;
  }
}

.study-entry-authors {
  @extend %authorlist;
  margin-bottom: 1rem;
}

.study-meta-data {
  margin-bottom: 1rem;

  .study-meta-item {
    @extend %text-xs;
    white-space: nowrap;
    margin-left: 0rem;
    margin-right: 1rem;
  }
}

#total-count-container {
  position: relative;
  padding-top: 2.5rem;
  margin-bottom: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    left: 50%;
    top: 0;
    height: 1.75rem;
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    border-top: 1.75rem solid $white;
    @include transform(translateX(-50%));
    @include transition(all 0.5s ease);
  }

  .total-count-title {
    color: $gray-600;
    font-weight: $font-weight-regular;
  }
}

@include media-breakpoint-down(sm) {
  .publication-item-mobile {
    margin-bottom: 2.5rem;
  }
}
