.toolbox-custom-stage-hero {
  .slick-slider {
    .slick-arrow {
      width: 2rem;
      height: 7rem;
      background-color: rgba($white, 0.2);
      color: $white;

      @include media-breakpoint-down(md) {
        height: 5rem;
      }

      &::before {
        background: $white;
        opacity: 0.5;
      }

      .swi {
        font-size: 1.5rem;
        opacity: 0.5;
        vertical-align: middle;

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
    }
  }

  .stage-hero-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .webgl-desktop-visibility {
    display: none;
  }

  .webgl-mobile-visibility {
    display: none;
  }
}
