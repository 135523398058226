$h1-color: $color-schwind-blue;
$h2-color: $color-schwind-black;
$h3-color: $color-schwind-blue;
$h4-color: $color-schwind-black;
$h5-color: $color-schwind-black;
$h6-color: $color-schwind-black;

$h1-color-secondary: $color-schwind-black;
$h2-color-secondary: $color-schwind-blue;
$h3-color-secondary: $color-schwind-black;
$h4-color-secondary: $color-schwind-blue;
$h5-color-secondary: $color-schwind-blue;
$h6-color-secondary: $color-schwind-blue;

$h1-fontsize: rem-calc(48px);
$h2-fontsize: rem-calc(32px);
$h3-fontsize: rem-calc(26px);
$h4-fontsize: rem-calc(18px);
$h5-fontsize: rem-calc(16px);
$h6-fontsize: rem-calc(14px);

$h1-mobile-fontsize: rem-calc(30px);
$h2-mobile-fontsize: rem-calc(24px);
$h3-mobile-fontsize: rem-calc(18px);
$h4-mobile-fontsize: rem-calc(18px);
$h5-mobile-fontsize: rem-calc(16px);
$h6-mobile-fontsize: rem-calc(14px);

$h1-lineheight: rem-calc(54px);
$h2-lineheight: rem-calc(42px);
$h3-lineheight: rem-calc(30px);
$h4-lineheight: rem-calc(24px);
$h5-lineheight: rem-calc(20px);
$h6-lineheight: rem-calc(20px);

$h1-mobile-lineheight: rem-calc(34px);
$h2-mobile-lineheight: rem-calc(24px);
$h3-mobile-lineheight: rem-calc(22px);
$h4-mobile-lineheight: rem-calc(22px);
$h5-mobile-lineheight: rem-calc(20px);
$h6-mobile-lineheight: rem-calc(20px);

$h1-margin-bottom: 1.5rem;
$h2-margin-bottom: 0.5rem;
$h3-margin-bottom: 0.5rem;
$h4-margin-bottom: 0.5rem;
$h5-margin-bottom: 0.5rem;
$h6-margin-bottom: 0.5rem;

$p-color: $black;
$p-lg-color: $p-color;
$p-md-color: $p-color;
$p-sm-color: $p-color;
$p-xs-color: $p-color;
$p-xxs-color: $p-color;

$p-fontsize: rem-calc(16px);
$p-lg-fontsize: rem-calc(24px);
$p-md-fontsize: rem-calc(18px);
$p-sm-fontsize: rem-calc(16px);
$p-xs-fontsize: rem-calc(14px);
$p-xxs-fontsize: rem-calc(12px);

$p-mobile-fontsize: rem-calc(16px);
$p-mobile-lg-fontsize: rem-calc(22px);
$p-mobile-md-fontsize: rem-calc(18px);
$p-mobile-sm-fontsize: rem-calc(16px);
$p-mobile-xs-fontsize: rem-calc(14px);
$p-mobile-xxs-fontsize: rem-calc(12px);

$p-lineheight: rem-calc(22px);
$p-lg-lineheight: rem-calc(30px);
$p-md-lineheight: rem-calc(26px);
$p-sm-lineheight: rem-calc(22px);
$p-xs-lineheight: rem-calc(20px);
$p-xxs-lineheight: rem-calc(16px);

$p-mobile-lineheight: rem-calc(20px);
$p-mobile-lg-lineheight: rem-calc(28px);
$p-mobile-md-lineheight: rem-calc(22px);
$p-mobile-sm-lineheight: rem-calc(20px);
$p-mobile-xs-lineheight: rem-calc(18px);
$p-mobile-xxs-lineheight: rem-calc(14px);

$p-margin-bottom: rem-calc(20px);
$p-lg-margin-bottom: rem-calc(28px);
$p-md-margin-bottom: rem-calc(22px);
$p-sm-margin-bottom: rem-calc(20px);
$p-xs-margin-bottom: rem-calc(18px);
$p-xxs-margin-bottom: rem-calc(14px);

$p-fontweight: $font-weight-regular;
$p-lg-fontweight: $font-weight-light;
$p-md-fontweight: $font-weight-regular;
$p-sm-fontweight: $font-weight-regular;
$p-xs-fontweight: $font-weight-regular;
$p-xxs-fontweight: $font-weight-regular;

$p-sizes: lg, md, sm, xs, xxs;
$p-colors: (lg: $p-lg-color, md: $p-md-color, sm: $p-sm-color, xs: $p-xs-color, xxs: $p-xxs-color);

$p-fontsizes: (lg: $p-lg-fontsize, md: $p-md-fontsize, sm: $p-sm-fontsize, xs: $p-xs-fontsize, xxs: $p-xxs-fontsize);
$p-mobile-fontsizes: (lg: $p-mobile-lg-fontsize, md: $p-mobile-md-fontsize, sm: $p-mobile-sm-fontsize, xs: $p-mobile-xs-fontsize, xxs: $p-mobile-xxs-fontsize);

$p-lineheights: (lg: $p-lg-lineheight, md: $p-md-lineheight, sm: $p-sm-lineheight, xs: $p-xs-lineheight, xxs: $p-xxs-lineheight);
$p-mobile-lineheights: (lg: $p-mobile-lg-lineheight, md: $p-mobile-md-lineheight, sm: $p-mobile-sm-lineheight, xs: $p-mobile-xs-lineheight, xxs: $p-mobile-xxs-lineheight);

$p-margins-bottom: (lg: $p-lg-margin-bottom, md: $p-md-margin-bottom, sm: $p-sm-margin-bottom, xs: $p-xs-margin-bottom, xxs: $p-xxs-margin-bottom);
$p-fontweights: (lg: $p-lg-fontweight, md: $p-md-fontweight, sm: $p-sm-fontweight, xs: $p-xs-fontweight, xxs: $p-xxs-fontweight);
