.gray-networking-area {
  $general-element-spacing: 1rem;

  .toolbox-snippet, .toolbox-custom-image-teaser {
    height: 100%;
  }

  .teaser-texts-wrapper, .toolbox-custom-image-teaser {
    padding: 2rem;
  }

  .gray-netowrking-area-button {
    height: 4rem;
  }

  .gray-networking-area-btn {
    @include media-breakpoint-up(xl) {
      position: absolute;
      bottom: 3rem;
    }
  }

  .gray-networking-area-text-btn {
    @include media-breakpoint-up(xl) {
      bottom: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    .no-padding-mobile {
      padding-left: 0;
      padding-right: 0;
    }

    .margintop-if-fullwidth {
      margin-top: $general-element-spacing;
    }
  }

  .teaser-image-box {
    text-align: center;
    padding: 0;
  }


  .teaser-image {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 100%;
    @include transform(translateY(-100%));

    @include media-breakpoint-down(xs) {
      max-width: 75%;
    }
  }

  // IE 10 and up
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .teaser-image {
      @include transform(translateY(0));
      bottom: 0;
      top: auto;

      @include media-breakpoint-up(sm) {
        position: absolute;
      }
    }
  }

  // Edge 12 - 16
  @supports (-ms-accelerator:true) {
    .teaser-image {
      @include transform(translateY(0));
      @include transform(translateX(-50%));
      bottom: 0;
      top: auto;

      @include media-breakpoint-up(sm) {
        position: absolute;
      }
    }
  }

  // Edge 16 and up
  @supports (-ms-ime-align:auto) {
    .teaser-image {
      @include transform(translateY(0));
      @include transform(translateX(-50%));
      bottom: 0;
      top: auto;

      @include media-breakpoint-up(sm) {
        position: absolute;
      }
    }
  }
}

.gray-onlinesupport-statement {
  .teaser-image {
    max-width: 140%;
    max-height: 100%;
    position: absolute;
    bottom: 0;
    left: -22px;
    z-index: 10;
    transform: translateY(-100%);

    @include media-breakpoint-down(xs) {
      max-width: 75%;
    }
  }
}
