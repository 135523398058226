.accordion {
  .pimcore_tag_input,
  .accordion-toggle {
    position: relative;
    text-decoration: none;
    border-top: 1px solid $primary;

    &:before {
      @include swi-icon;
      content: $swi-chevron-up;
      position: absolute;
      top: 0;
      right: 0;
      width: 3rem;
      height: 3.5rem;
      text-align: center;
      font-size: 1.25rem;
      line-height: 3.5rem;
      color: $primary;
      @include transition(transform 0.4s ease);
    }

    &:hover {
      background-color: $gray-100;
    }

    &.collapsed {
      border-top: none;

      &:before {
        @include transform(rotate(-180deg));
      }
    }

    &:not(.collapsed) {
      background-color: $gray-100;
      font-weight: $font-weight-bold;
    }
  }

  .card {
    border: none;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;

    &:last-child {
      border-bottom: none;
    }
  }

  .card-header {
    padding: 0;
    background-color: $white;
    border: none;

    a,
    .pimcore_tag_input {
      display: block;
      padding: 1rem 2.5rem 1rem 0.75rem;
      overflow: hidden;
    }
  }

  .card-body {
    padding: 1rem 2.5rem 1rem 0.75rem;
  }
}
