

.mypage-header-slider {
  img {
    min-height: 9.25rem;
  }

  .slick-arrow {
    display:none !important;
  }

  &:hover {
    .slick-arrow {
      display:block !important;
    }
  }
}

.mypage-container {
  margin-top: 3rem;
  margin-bottom: 7rem;
}

@include media-breakpoint-up(md) {
  .mypage-col-left {
    padding-right: 30px;
  }

  .mypage-col-right {
    padding-left: 30px;
  }
}

.mypage-intro {
  margin-bottom: 5rem;
}

.mypage-h2-title {
  margin-bottom: 1.5rem;
}

.mypage-event-list {
  margin: 0;
  padding: 1rem 0;

  &:nth-child(odd) {
    background-color: $light;
  }

  &:nth-child(even) {
    background-color: rgba($light, 0.4);
  }

}

.mypage-product {
  margin: 3.5rem 0;

  &-text {
    margin-bottom: 1rem;
  }
}

.mypage-image-gallery {
  margin-top: 3rem;

  &-image {
    margin-top: 1rem;
    height: auto;
  }

  &-text {
    margin-bottom: 1rem;
  }
}

.mypage-video {
  position: relative;
  margin: 2rem 0;
  .pimcore_tag_video {

    iframe {
      min-height: 250px;
    }
    @include media-breakpoint-up(md) {
      iframe {
        min-height: 300px;
      }
    }

    @include media-breakpoint-up(lg) {
      iframe {
        min-height: 400px;
      }
    }

  }
}

.mypage-results-button {
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  text-align: right;
}

.mypage-company-logo {
  margin-bottom: 2rem;
  padding: 20px;
  border: 1px solid $gray-300;
  clear: both;
}

.mypage-person-card {
  padding-left: 0;
  margin: 1rem 0;
  background: $light;

  &-image-cell {
    padding: 0;
    width: 95px;
    img {
      width: 100%;
    }
  }

  &-content-cell {
    vertical-align: top;
  }
  &-content {
    padding: 0.75rem 0.9rem;
  }
}

.mypage-social-media {
  margin-top: 1rem;
  color: $primary;

  .swi {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    display: inline-block;
    font-size: 1.25rem;
  }
}


.mypage-contact {
  margin-bottom: 1.5rem;

  .swi {
    margin-right: 0.5rem;
    display: inline-block;
    color: $primary;
  }

  .location-icon {
    position: relative;

    .swi {
      position: absolute;
      top: 0.9rem;
    }
  }

  .location-content {
    padding-left: 1.5rem;
  }
}

#mypage-location-map {
  width: 100%;
  height: 250px;
}


@include media-breakpoint-down(md) {
  .mypage-intro {
    margin-bottom: 3rem;
  }

  .mypage-h2-title {
    margin-bottom: 1rem;
  }

  .mypage-event-list-text {
    padding-left: 0;
  }

  .mypage-company-logo {
    margin-top: 2rem;
  }

  #mypage-location-map {
    height: 150px;
  }

  .approval-btn {
    margin: 1.5rem 0;
  }
}

@include media-breakpoint-down(sm) {
  .mypage-col-left {
    padding-right: 15px;
  }

  .mypage-col-right {
    padding-left: 15px;
  }

  .mypage-representative-container {
    margin-top: 2rem;
  }

  .mypage-product-gallery {
    &-image {
      margin-bottom: 1.5rem;
    }
    &-content {
      padding-left: 0;
    }
  }

  #mypage-location-map {
    height: 250px;
  }
}

