@import "variables";

@font-face {
  font-family: 'schwindicons';
  src:  url('../../../fonts/schwindicons.eot?1k9kxd');
  src:  url('../../../fonts/schwindicons.eot?1k9kxd#iefix') format('embedded-opentype'),
  url('../../../fonts/schwindicons.ttf?1k9kxd') format('truetype'),
  url('../../../fonts/schwindicons.woff?1k9kxd') format('woff'),
  url('../../../fonts/schwindicons.svg?1k9kxd#schwindicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin swi-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable */
  font-family: 'schwindicons' !important;
  /* stylelint-enable */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.swi {
  @include swi-icon;

  &.gray:before {
    color: $gray-500;
  }
}

.swi-download {
  &:before {
    content: $swi-download;
  }
}

.swi-mail {
  &:before {
    content: $swi-mail;
  }
}

.swi-location {
  &:before {
    content: $swi-location;
  }
}

.swi-signpost {
  &:before {
    content: $swi-signpost;
  }
}

.swi-clock {
  &:before {
    content: $swi-clock;
  }
}

.swi-phone {
  &:before {
    content: $swi-phone;
  }
}

.swi-user {
  &:before {
    content: $swi-user;
  }
}

.swi-locked {
  &:before {
    content: $swi-locked;
  }
}

.swi-unlocked {
  &:before {
    content: $swi-unlocked;
  }
}

.swi-checkmark {
  &:before {
    content: $swi-checkmark;
  }
}

.swi-plus {
  &:before {
    content: $swi-plus;
  }
}

.swi-cross {
  &:before {
    content: $swi-cross;
  }
}

.swi-output {
  &:before {
    content: $swi-output;
  }
}

.swi-file-document {
  &:before {
    content: $swi-file-document;
  }
}

.swi-desktop {
  &:before {
    content: $swi-desktop;
  }
}

.swi-browser {
  &:before {
    content: $swi-browser;
  }
}

.swi-comments {
  &:before {
    content: $swi-comments;
  }
}

.swi-crosshair {
  &:before {
    content: $swi-crosshair;
  }
}

.swi-eye {
  &:before {
    content: $swi-eye;
  }
}

.swi-7d {
  &:before {
    content: $swi-7d;
  }
}

.swi-6d {
  &:before {
    content: $swi-6d;
  }
}

.swi-5d {
  &:before {
    content: $swi-5d;
  }
}

.swi-ablation-1-3s {
  &:before {
    content: $swi-ablation-1-3s;
  }
}

.swi-ablation-1-5s {
  &:before {
    content: $swi-ablation-1-5s;
  }
}

.swi-ablation-2s {
  &:before {
    content: $swi-ablation-2s;
  }
}

.swi-corpus {
  &:before {
    content: $swi-corpus;
  }
}

.swi-compact {
  &:before {
    content: $swi-compact;
  }
}

.swi-laserarm {
  &:before {
    content: $swi-laserarm;
  }
}

.swi-laser {
  &:before {
    content: $swi-laser;
  }
}

.swi-schwind-cam {
  &:before {
    content: $swi-schwind-cam;
  }
}

.swi-smartsurface {
  &:before {
    content: $swi-smartsurface;
  }
}

.swi-premium {
  &:before {
    content: $swi-premium;
  }
}

.swi-pulse-rate {
  &:before {
    content: $swi-pulse-rate;
  }
}

.swi-healing-process {
  &:before {
    content: $swi-healing-process;
  }
}

.swi-speed {
  &:before {
    content: $swi-speed;
  }
}

.swi-safety {
  &:before {
    content: $swi-safety;
  }
}

.swi-economics {
  &:before {
    content: $swi-economics;
  }
}

.swi-load-more {
  &:before {
    content: $swi-load-more;
  }
}

.swi-bullet-list {
  &:before {
    content: $swi-bullet-list;
  }
}

.swi-share {
  &:before {
    content: $swi-share;
  }
}

.swi-share-filled {
  &:before {
    content: $swi-share-filled;
  }
}

.swi-facebook {
  &:before {
    content: $swi-facebook;
  }

  &.colored:before {
    color: $swi-color-facebook;
  }

  &.invert,
  &-invert {
    &:before {
      content: $swi-facebook-invert;
    }
  }
}

.swi-twitter {
  &:before {
    content: $swi-twitter;
  }

  &.colored:before {
    color: $swi-color-twitter;
  }

  &.invert,
  &-invert {
    &:before {
      content: $swi-twitter-invert;
    }
  }
}

.swi-youtube {
  &:before {
    content: $swi-youtube;
  }

  &.colored:before {
    color: $swi-color-youtube;
  }
}

.swi-linkedin {
  &:before {
    content: $swi-linkedin;
  }

  &.colored:before {
    color: $swi-color-linkedin;
  }

  &.invert,
  &-invert {
    &:before {
      content: $swi-linkedin-invert;
    }
  }
}

.swi-whatsapp {
  &:before {
    content: $swi-whatsapp;
  }

  &.colored:before {
    color: $swi-color-whatsapp;
  }

  &.invert,
  &-invert {
    &:before {
      content: $swi-whatsapp-invert;
    }
  }
}

.swi-google {
  &:before {
    content: $swi-google;
  }
}

.swi-instagram {
  &:before {
    content: $swi-instagram;
  }

  &.colored:before {
    color: $black;
  }
}

.swi-baidu {
  &:before {
    content: $swi-baidu;
  }
}

.swi-qzone {
  &:before {
    content: $swi-qzone;
  }
}

.swi-sina-weibo {
  &:before {
    content: $swi-sina-weibo;
  }
}

.swi-vk {
  &:before {
    content: $swi-vk;
  }
}

.swi-wechat {
  &:before {
    content: $swi-wechat;
  }
}

.swi-globe {
  &:before {
    content: $swi-globe;
  }
}

.swi-search {
  &:before {
    content: $swi-search;
  }
}

.swi-zoom-in {
  &:before {
    content: $swi-zoom-in;
  }
}

.swi-zoom-out {
  &:before {
    content: $swi-zoom-out;
  }
}

.swi-chevron-left {
  &:before {
    content: $swi-chevron-left;
  }
}

.swi-chevron-right {
  &:before {
    content: $swi-chevron-right;
  }
}

.swi-chevron-up {
  &:before {
    content: $swi-chevron-up;
  }
}

.swi-chevron-down {
  &:before {
    content: $swi-chevron-down;
  }
}

.swi-arrow-left-square {
  &:before {
    content: $swi-arrow-left-square;
  }
}

.swi-arrow-right-square {
  &:before {
    content: $swi-arrow-right-square;
  }
}

.swi-arrow-up-square {
  &:before {
    content: $swi-arrow-up-square;
  }
}

.swi-arrow-down-square {
  &:before {
    content: $swi-arrow-down-square;
  }
}

.swi-arrow-left-circle {
  &:before {
    content: $swi-arrow-left-circle;
  }
}

.swi-arrow-right-circle {
  &:before {
    content: $swi-arrow-right-circle;
  }
}

.swi-arrow-up-circle {
  &:before {
    content: $swi-arrow-up-circle;
  }
}

.swi-arrow-down-circle {
  &:before {
    content: $swi-arrow-down-circle;
  }
}

.swi-cross-circle {
  &:before {
    content: $swi-cross-circle;
  }
}

.swi-plus-circle {
  &:before {
    content: $swi-plus-circle;
  }
}

.swi-minus-circle {
  &:before {
    content: $swi-minus-circle;
  }
}

.swi-attachment {
  &:before {
    content: $swi-attachment;
  }
}

.swi-attachment-2 {
  &:before {
    content: $swi-attachment-2;
  }
}

.swi-award-badge {
  &:before {
    content: $swi-award-badge;
  }
}

.swi-backspace {
  &:before {
    content: $swi-backspace;
  }
}

.swi-bell {
  &:before {
    content: $swi-bell;
  }
}

.swi-bookmark {
  &:before {
    content: $swi-bookmark;
  }
}

.swi-briefcase {
  &:before {
    content: $swi-briefcase;
  }
}

.swi-calendar {
  &:before {
    content: $swi-calendar;
  }
}

.swi-calendar-download {
  &:before {
    content: $swi-calendar-download;
  }
}

.swi-checkmark-square {
  &:before {
    content: $swi-checkmark-square;
  }
}

.swi-clipboard {
  &:before {
    content: $swi-clipboard;
  }
}

.swi-delete {
  &:before {
    content: $swi-delete;
  }
}

.swi-duplicate {
  &:before {
    content: $swi-duplicate;
  }
}

.swi-equalizer {
  &:before {
    content: $swi-equalizer;
  }
}

.swi-flag {
  &:before {
    content: $swi-flag;
  }
}

.swi-folder-2 {
  &:before {
    content: $swi-folder-2;
  }
}

.swi-grid {
  &:before {
    content: $swi-grid;
  }
}

.swi-grid-2 {
  &:before {
    content: $swi-grid-2;
  }
}

.swi-hamburger {
  &:before {
    content: $swi-hamburger;
  }
}

.swi-heart {
  &:before {
    content: $swi-heart;
  }
}

.swi-keynote-presentation {
  &:before {
    content: $swi-keynote-presentation;
  }
}

.swi-layers {
  &:before {
    content: $swi-layers;
  }
}

.swi-multiple-documents-files {
  &:before {
    content: $swi-multiple-documents-files;
  }
}

.swi-new {
  &:before {
    content: $swi-new;
  }
}

.swi-note-text {
  &:before {
    content: $swi-note-text;
  }
}

.swi-notebook {
  &:before {
    content: $swi-notebook;
  }
}

.swi-permalink {
  &:before {
    content: $swi-permalink;
  }
}

.swi-pie-chart {
  &:before {
    content: $swi-pie-chart;
  }
}

.swi-play {
  &:before {
    content: $swi-play;
  }
}

.swi-refresh {
  &:before {
    content: $swi-refresh;
  }
}

.swi-replay {
  &:before {
    content: $swi-replay;
  }
}

.swi-reply {
  &:before {
    content: $swi-reply;
  }
}

.swi-save-floppy {
  &:before {
    content: $swi-save-floppy;
  }
}

.swi-sort-down {
  &:before {
    content: $swi-sort-down;
  }
}

.swi-sort-up {
  &:before {
    content: $swi-sort-up;
  }
}

.swi-tag {
  &:before {
    content: $swi-tag;
  }
}

.swi-tag-2 {
  &:before {
    content: $swi-tag-2;
  }
}

.swi-trash {
  &:before {
    content: $swi-trash;
  }
}

.swi-collapse {
  &:before {
    content: $swi-collapse;
  }
}

.swi-expand {
  &:before {
    content: $swi-expand;
  }
}

.swi-decrease {
  &:before {
    content: $swi-decrease;
  }
}

.swi-enlarge {
  &:before {
    content: $swi-enlarge;
  }
}

.swi-zoom {
  &:before {
    content: $swi-zoom;
  }
}

.swi-preferences {
  &:before {
    content: $swi-preferences;
  }
}

.swi-movie-recorder {
  &:before {
    content: $swi-movie-recorder;
  }
}

.swi-image-picture {
  &:before {
    content: $swi-image-picture;
  }
}

.swi-camera {
  &:before {
    content: $swi-camera;
  }
}

.swi-print {
  &:before {
    content: $swi-print;
  }
}

.swi-microphone {
  &:before {
    content: $swi-microphone;
  }
}

.swi-microphone-off {
  &:before {
    content: $swi-microphone-off;
  }
}

.swi-volume {
  &:before {
    content: $swi-volume;
  }
}

.swi-volume-full {
  &:before {
    content: $swi-volume-full;
  }
}

.swi-volume-muted {
  &:before {
    content: $swi-volume-muted;
  }
}

.swi-volume-off {
  &:before {
    content: $swi-volume-off;
  }
}

.swi-info {
  &:before {
    content: $swi-info;
  }
}

.swi-atos-anwenderfreundlich {
  &:before {
    content: $swi-atos-anwenderfreundlich;
  }
}
.swi-atos-eyetracking {
  &:before {
    content: $swi-atos-eyetracking;
  }
}
.swi-atos-flapdurchmesser {
  &:before {
    content: $swi-atos-flapdurchmesser;
  }
}
.swi-atos-kontaktelement {
  &:before {
    content: $swi-atos-kontaktelement;
  }
}
.swi-atos-lentikelgeometrie {
  &:before {
    content: $swi-atos-lentikelgeometrie;
  }
}
.swi-atos-zentrierung {
  &:before {
    content: $swi-atos-zentrierung;
  }
}
.swi-atos-zufriedenheit {
  &:before {
    content: $swi-atos-zufriedenheit;
  }
}
.swi-watch {
  &:before {
    content: $swi-watch;
  }
}
.swi-atos-icrs {
  &:before {
    content: $swi-atos-icrs;
  }
}
.swi-atos-lowdose {
  &:before {
    content: $swi-atos-lowdose;
  }
}
.swi-foresight-demonstration {
  &:before {
    content: $swi-foresight-demonstration;
  }
}
.swi-foresight-feedback-double-check {
  &:before {
    content: $swi-foresight-feedback-double-check;
  }
}
.swi-foresight-one-click {
  &:before {
    content: $swi-foresight-one-click;
  }
}
.swi-foresight-training {
  &:before {
    content: $swi-foresight-training;
  }
}


