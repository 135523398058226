
.news-entry-authors {
  @extend %authorlist;
  margin-bottom: $space-1;
}


.news-list-entries {

  .news-image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .badge-date {
    font-weight: $font-weight-regular;
  }
  img {
    width: 100%;
    height: auto !important;
  }


  .news-detail-link {
    float:right;
  }

  .news-text-column {
    padding-top: $space-1;
    padding-bottom: $space-3; //For .news-meta-info
    padding-left: $space-2;
    padding-right: $space-2;
  }


  .news-meta-info {
    position: absolute;
    bottom: $space-1;
    left: 0;
    right: 0;
    padding-left: $space-2;
    padding-right: $space-2;
  }


  .news-list-entry {
    background-color: $white;
    margin-bottom: $space-2;

    .news-entry-metas {
      margin-bottom: 1.5rem;
    }

    //Special types
    &.news-list-page-entry {
      //News List Page
      .news-text-column-content {
        min-height: 130px;
      }
    }
    &.userreport-list-page-entry {
      //User Reports List page
      .news-text-column-content {
        min-height: 130px;
      }
    }
    &.experiences-list-page-entry {
      //Experiences List page
      .news-text-column-content {
        min-height: 130px;
      }

    }
    &.brick-infocenter-news-list-entry {
      //List on infocenter page
      .news-text-column-content {
        min-height: 110px;
      }
    }
    &.brick-generic-news-list-entry {
      //currently not used?
      .news-text-column-content {
        min-height: 110px;
      }
    }
    &.brick-infocenter-news-teaser-entry {
      //Top of infocenter page

    }
    &.brick-homepage-news-teaser-entry {
      //News teasers on homepage
    }

  }



  &.custom-news-list-newslist-twothird {
    p {
      @extend %text-xs
    }
  }


  @include media-breakpoint-up(lg) {

    .news-text-column-at-left {
      padding-left: $space-1;
      padding-right:0;
      .news-text-column-content {
        padding-left: $space-1;
      }
    }
    .news-text-column-at-right {
      padding-left: 0;
      padding-right: $space-1;
      .news-text-column-content {
        padding-right: $space-1;
      }
    }

    .news-text-column-fullwitdh {
      padding-left: $space-1;
      padding-right: $space-1;
    }




    //--- Brick Infocenter teaser / hero

    &.custom-news-list-newsteaser {
      .news-text-column-content {
        padding-right: $space-1;
      }
      .news-meta-info {
        padding-left: $space-2;
        padding-right: $space-2;
      }
    }

    //--- Brick Infocenter list
    &.custom-news-list-newslist-twothird {
      .news-text-column-at-left {
        padding-right:0;
        padding-left: $space-1;
        .news-meta-info {
          padding-left: $space-2;
          padding-right: 0;
        }
      }
      .news-text-column-at-right {
        padding-left: 0;
        padding-right: $space-1;
        .news-meta-info {
          padding-left: 0;
          padding-right: $space-2;
        }
      }
      .news-text-column-fullwitdh {
        padding-left: 0;
        padding-right: $space-1;
        .news-meta-info {
          padding-right: $space-2;
        }
      }
    }


    //News List page

    &#page-news-ajax-result-wrapper {



      .news-meta-info {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      .news-text-column-at-left {
        padding-left: $space-2;
        padding-right:$space-1;
        .news-meta-info {
          padding-left: $space-3;
          padding-right: $space-2;
        }
      }
      .news-text-column-at-right {
        padding-left: $space-1;
        padding-right: $space-2;
        .news-meta-info {
          padding-left: $space-1;
        }
      }

      .news-text-column-fullwitdh {
        padding-left: $space-1;
        padding-right: $space-1;
        .news-meta-info {

          padding-left: $space-3;
          padding-right: $space-3;
        }
      }
    }
  }

}


.related-news-entry {
  margin-top: $space-1;
  margin-bottom: $space-1;

  h3,
  h4 {
    margin-bottom: 0;
  }

  .news-meta-info {
    margin-bottom: 1rem;

    .badge-date {
      font-weight: $font-weight-regular;
    }
  }
}

.related-news-entry + .related-news-entry {
  border-top: 1px solid $color-separator;
  padding-top: $space-1;
}
