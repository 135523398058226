%authorlist {
  @extend %text-xxs;
  color: $link-color;

  &.authors-linked {
    color: $link-color;

    .swi {
      color: $link-color;
    }
  }

  a + a,
  span + span {
    &::before {
      content: '/';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
}
