.schwindicon-select-buttons {
  .x-form-text-default {
    @include swi-icon;
    line-height: 1;
    font-size: 1.5rem;
  }

  li {
    @include swi-icon;
    line-height: 1;
    font-size: 1.5rem;
  }
}
