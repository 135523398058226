.toolbox-custom-publication_list {
  .headerrow {
    margin-bottom: 2rem;
  }

  .publication-teaser-text {
    color: $color-schwind-darkgray;
  }

  @include media-breakpoint-up(sm) {
    .link-listpage-container {
      float: right;
    }
  }

  .study-list-entry {
    img {
      margin-bottom: 10px;
    }
  }
}
