.toolbox-custom-iconlist {

  .iconlist-content {
    position: relative;
    padding: 0.5rem 0 0.5rem 2.5rem;

    @include media-breakpoint-up(lg) {
      margin: 0 4rem;
    }
  }

  .iconlist-column {
    margin-bottom: 1rem;
  }

  .iconlist-row-icon {
    @extend %h3;
    font-size: 1.75rem;
    margin-right: 0.5rem;
    position: absolute;
    top: 0.75rem;
    left: 0;
  }

  .iconlist-row-text {
    margin: 0;
  }

  &.has-dark-bg {
    .iconlist-row-title,
    .iconlist-row-icon,
    .iconlist-row-text,
    .pimcore_editable {
      color: $white;
    }
  }

  &.product-hero {
    .iconlist-content {
      padding-left: 4rem;
    }

    .iconlist-row-icon {
      font-size: 2.5rem;
      top: 1rem;
      left: -1rem;
    }

    @include media-breakpoint-down (md) {
      .iconlist-content {
        margin-left: 1.5rem;
      }
      .iconlist-column {
        margin-left: 1rem;
      }
    }
  }
}

body[data-edit] .toolbox-custom-iconlist {
  padding: 0 $general-element-spacing;

  > .row {
    margin: 0;
  }
}
