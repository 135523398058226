.toolbox-custom-download-list {
  margin-bottom: 2em;

  .download-list-item {
    margin: 0.3em 0;

    .download-list-icon {
      margin-right: 0.4em;
    }
  }
}
