.event-detail {
  .description {
    font-size: $font-size-lg;
  }

  h1,
  h2 {
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 1.5rem;
  }
}

.event-meta-data {
  .badge {
    @extend %text-xs;
    padding-left: 0;
    padding-right: 1rem;

    .swi {
      margin-right: 0.2rem;
    }
  }

  .event-meta + .event-meta {
    &:before {
      content: ' ';
      margin-right: 0.25rem;
    }

    &.show-prefix:before {
      content: ',';
    }
  }
}

#events-load-more-list {
  margin-top: $space-2;

  .event-list-entry {
    background-color: $container-primary-bg;
    margin-top: $space-1;

    .event-text-column {
      padding: 1.5rem;
    }

    .event-text-column-title {
      color: $primary;
      font-size: 1.5rem;

      @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
      }
    }

    p {
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-down(md) {
      .event-list-btn-container {
        text-align: left;
        padding-left: 1.5rem;
        padding-bottom: 2rem;
      }
    }

    @include media-breakpoint-up(md) {
      .event-list-btn-container {
        position: relative;
        text-align: right;
        height: 100%;

        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}

#event-map {
  width: 100%;
  height: 20rem;
}

#events-overview-map {
  width: 100%;
  height: 30rem;
}

#event-info-template {
  display: none;
}

.eventmap-info {
  .date-info {
    display: block;
  }

  .btn {
    margin-top: 1rem;
  }
}
