$btn-focus-width: 0;
$btn-slide-bg: $white;
$btn-slide-color: $primary;
$btn-loadmore-color: $color-schwind-lightblue;
$btn-disabled-opacity: 0.5;
$btn-padding-y-sm: 0.25rem;
$btn-padding-y-lg: 0.69rem;
$btn-padding-x-lg: 1rem;
$btn-padding-y: 0.28rem;
$btn-padding-x: 0.75rem;
$btn-line-height-sm: 0.9rem;
$btn-font-size-lg: 15px;
$btn-font-size: 14px;
$btn-font-size-sm: 12px;
