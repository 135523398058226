@use "sass:math";

.modal-header {
  padding-bottom: math.div($modal-header-padding, 2);

  @include media-breakpoint-down(sm) {
    padding-left: 1.5rem;
  }

  .close {
    padding: 0 math.div($modal-header-padding, 2);
    font-size: 3rem;
    font-weight: 300;
    outline: none;
    margin: -4rem -4rem -4rem auto;
  }
}

.modal-dialog {
  @include media-breakpoint-up(md) {
    max-width: 690px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px;
  }
}

.modal-title {
  @extend %h3;
  color: $black;
  font-weight: $font-weight-regular;
}

.modal-footer {
  align-items: start;
  justify-content: flex-start;

  /* Fix for display problem with variable */
  padding: 0 4rem 2rem 4rem;

  @include media-breakpoint-down(sm) {
    padding: 0 4rem 2rem 1.5rem;
  }

  > :not(:last-child) {
    margin-right: .25rem;
  }

  > :not(:first-child) {
    margin-left:.25rem
  }

}

.modal-footer-hint {
  @extend %text-xs;
  margin: 0;
  text-align: right;
}

@include media-breakpoint-down(sm) {
  .modal-body {
    padding: 0 1.5rem 2rem 1.5rem;
  }
}
