.toolbox-custom-milestones {
  .milestone {
    margin: 1rem 0rem;
    padding: 0;

    .milestone-container {
      background-color: $white;
      margin: 0 1rem;
      height: 100%;
      .milestone-wysiwyg {
        p {
          margin-bottom: 0;
        }
        ul {
          padding-left: 1.5rem;
        }
      }
    }

    .milestone-image > picture,
    .milestone-image > picture > img {
      position: relative;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }

    .milestone-content {
      padding-top: 0.5rem;

      .milestone-year {
        font-size: 2em;
        color: $primary;
      }

      .milestone-name {
        font-weight: bold;
        padding-top: 0.25rem;
        color: $primary;
      }

      .milestone-list {
        color: $black;
        padding-left: 1.5rem;
      }
    }
  }
}
