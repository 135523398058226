.mediacenter-filters {
  padding-bottom: 2rem;

  .btn,
  .select2.select2-container {
    min-width: 100%;
    max-width: 100%;
  }

  .select2.select2-container {
    margin-bottom: 1rem;
  }
}

.mediacenter-container {
  padding-top: 3.5rem;
  padding-bottom: 2rem;

  .load-more-container {
    text-align: center;
  }

  .loading-indicator {
    display: none;
    padding: 0.1rem 0 1.5rem 0;
  }
}

.mediacenter-entry {
  @extend %grid-nogutter;
  border: none;
  border-radius: 0;
  height: 100%;

  .card-block {
    padding: 1rem;

    .card-title {
      font-size: 0.875rem;
    }

    .swi {
      margin-right: 0.25rem;
      font-size: 1rem;
    }
  }

  .card-block-bottom {
    padding-left: 1rem;
    padding-bottom: 1rem;

    .swi-download {
      font-size: 1.125rem;
    }
  }

  .light-box {
    &,
    .item,
    .img {
      display: block;
    }
  }

  .overlayed-preview-image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position-x: 50%;
    }
}

