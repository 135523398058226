@use "sass:color";
@use "sass:math";

.swi-breadcrumb {
  @include make-container;
  @include make-container-max-widths;

  @extend %text-xs;

  margin: 1.5rem auto 3rem auto;
  padding: 0.5rem math.div($grid-gutter-width , 2);
  line-height: 1.25;

  span {
    white-space: nowrap;
  }

  .hide-in-breadcrumb {
    display: none;
  }

  > a, span.with-arrow {
    position: relative;
    padding-right: 1.75rem;
    white-space: nowrap;

    &:after {
      @include swi-icon;
      content: $swi-chevron-right;
      position: absolute;
      top: 0.25rem;
      right: 0.5rem;
      font-size: 0.75em;
      line-height: 1.6;
    }
  }


}

body:not([data-edit]) {
  .swi-breadcrumb-dark {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    z-index: 1;
    color: $white;

    a:hover {
      color: color.adjust($link-hover-color, $lightness: 25%);
    }
  }
}
