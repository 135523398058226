.toolbox-custom-contact-list {
  .representative-contact {
    min-width: 100px;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  &.center {
    .representative-contact-wrapper {
      justify-content: center;
    }
  }
}
