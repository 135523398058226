.toolbox-column {
  height: 100%;

  .toolbox-image img {
    max-width: 100%;
  }
}

.toolbox-columns-nopadding .row {
  margin: 0;

  [class^="col-"],
  [class*=" col-"] {
    padding: 0;
  }
}
