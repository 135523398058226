$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%233094B4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$slider-bg: $white;
$slider-bg-active: $white;
$slider-border-color: $gray-500;
$slider-border-color-active: $primary;
$slider-border-focused: $primary;
$slider-inner-width: 30px;
$slider-inner-height: 14px;
$slider-padding: 2px;
$slider-border-radius: 2px;
