
.mypage-publishing-area {
  .ajax-publish-indicator {
    display: none;
    &.show {
      display: inline;
    }
  }

  .show-on-publish-ajax-error-message {
    display: none;
    &.show {
      display: block;
    }
  }

}

.mypage-edit-boilerplate {
  padding: 3rem 0;
  background-color: $container-secondary-bg;

  .checkbox-label {
    transform: translateY(-100%);
  }
}

/* My Page Edit Styles */
.mypage-edit-intro {
  background-color: $container-secondary-bg;
  position: relative;

  .content-col {
    padding-top: 4rem;
    padding-bottom: 1rem;

    .checkbox-label {
      transform: translateY(-100%);
    }
  }

  .img-container {
    .img-right {
      position: absolute;
      max-width: 475px;
      height: 100%;
      right: -15px;
      top: 0;
    }

    @include media-breakpoint-down(md) {
      text-align: center;

      .img-right {
        position: relative;
      }
    }
  }
}

.mypage-edit-form#imprintlink_section {
  margin-bottom: 3rem;
}


#mypage-editor {
  .hidden {
    display: none;
  }

  .no-bottom-margin {
    margin-bottom: 0;
  }

  .mypage-email {
    border-right: 1px solid $gray-400;

    .input-group-append {
      margin-left: -1px;
      position: absolute;
      right: 0;
      top: 50%;

      .input-group-text {
        border: none;
      }
    }
  }

  .ankerlinks {
    padding: 3rem 0 4rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }


  input {

    @include placeholder {

      font-size: $p-xs-fontsize;
    }
  }

  textarea {
    @include placeholder {

      font-size: $p-xs-fontsize;
    }
  }

  .is-file-uploaded {
    input {
      @include placeholder {
        color: black;
        opacity: initial;
      }
    }
  }

  .select2-selection__placeholder {
    font-size: $p-xs-fontsize;
  }

  &.mypage-card-mode {
    .mypage-pagemode-only-section {
      display: none ;
    }
  }

  &.mypage-page-mode {
    .mypage-cardmode-only-section {
      display: none ;
    }
  }

  .mypage-is-draft-only-section,
  .mypage-is-draft-only-span {
    display: none;
  }
  &.is-draft-mode {
    .mypage-is-draft-only-span {
      display: inline;
    }
    .mypage-is-draft-only-block {
      display: block;
    }
    .hide-in-draft-mode {
      display: none !important;
    }
  }

  .show-on-review-requested {
    display: none;
  }

  &.is-review-requested {
    .show-on-review-requested {
      display: initial;
    }
    .hide-on-review-requested {
      display: none !important;
    }
  }

  .wide-button {
    min-width: 200px !important;
  }

  .invalid-feedback {
    &.show {
      display: block;
    }
  }

  .approval-status-label {
    width: 70%;
    padding: 0.5rem;
  }

  .ajax-submit-indicator {
    display: none;
    &.show {
      display: inline;
    }
  }

  .form-error-message {
    display: none;
    &.show {
      display: block;
    }
  }


  .custom-file-container__image-preview {
    width: 200px;
    height: 200px;
    background-color: $container-secondary-bg;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .custom-file-container__image-multi-preview {
      max-width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .upload-image-spinner {
    display: none;
    &.show {
      display: block;
    }
  }

  .upload-error-message-container {
    display: none;
    &.show {
      display: block;
    }
    .error-message {
      display: none;
      &.show {
        display: block;
      }
    }
  }

  .img-hidden {
    display: none;
  }

  .form-control.hidden {
    display: none;
  }

  .fake-input {
    display: flex;
    justify-content: space-between;

    input.fake-input-left {
      border: none;
      padding-right: $space-1;
      display: inline;
    }

    &-right {
      transform: translateY(-10%);
      white-space:nowrap;
      .fake-input-button {
        color: white !important;
        &.btn-light {
          color: $color-schwind-lightblue !important;
        }
      }
    }
  }

  .form-control {
    .if-file-uploaded {
      display: none;
    }
    &.is-file-uploaded {
      .if-file-uploaded {
        display: inline;
      }
      .if-file-not-uploaded {
        display: none;
      }
    }
  }


  .mypage-edit-form {
    margin: 0;

    &.small-top-margin {
      margin-top: 1rem !important;
    }

    &:not(.title) {
      margin-top: 5rem;
    }

    input {
      width: 100%;
    }

    .input-col {
      padding-left: 0;
      padding-right: 0;
    }

    .hint-col {
      padding-left: 0;
      padding-right: 0;
      .mypage-hint-outter {
        background-color: $gray-100;
        margin-top: 15px;
        display: table;
        width: 100%;
        border-collapse: separate;
        border-spacing: 10px;

        .mypage-hint {
          display: table-row;
          &-large {
            height: 3.5rem;
          }
          .icon-column, .hint-text {
            display: table-cell;
          }
          .icon-column {
            vertical-align: middle;
          }
          .hint-text {
            vertical-align: middle;
            font-size: 0.65rem;
            line-height: 1.2;
          }
        }

      }
    }

    .content-col {
      margin: 1rem 0;
    }

    .content-col-sm {
      margin: 0.5rem 0;
    }

    .mypage-edit-products-textarea {
      height: 150px;
    }

    &.mypage-edit-textarea-container {
      .mypage-edit-textarea {
        height: 300px;


        &.textarea-short {
          height: 80px;
        }
      }
    }

    &.mypage-edit-logo-container {

      .header-image {
        max-height: 100px;

        img {
          height: 100%;
        }
      }
    }

    .get-location-button,
    .get-location-button:hover {
      color: white;
    }

    &.mypage-form-submit {
      position: sticky;
      padding: 20px 0;
      margin-top: 0;
      bottom: 0;
      background-color: #053951;

      .mypage-btn {
        width: 49%;
      }
    }

    &.mypage-edit-distribution {
      .select2-selection__rendered {
        padding: 0.3rem 0;
        .select2-selection__choice {
          background-color: $secondary;
          color: $white;

          .select2-selection__choice__remove {
            color: $white;
            float: right;
            margin-left: 1.5rem;
            font-size: 1.5rem;
            line-height: 0.6;
            font-weight: normal;
          }
        }
      }
    }

    .select2-selection--multiple {
      &::before,
      &::after {
        position: absolute;
        /* stylelint-disable */
        font-family: schwindicons !important;
        /* stylelint-enable */
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: .9rem;
        color: #3094b4;
        right: 10px;
      }

      &::before {
        top: 1rem;
        content: "\E96F";
      }

      &::after {
        top: 1.75rem;
        content: "\E95A";
      }
    }

    @include media-breakpoint-up(md) {
      .input-col {
        padding-left: 0;
        padding-right: 15px;
      }

      .hint-col {
        position: relative;
        padding-left: 15px;
        padding-right: 0;

        &.top-1 {
          top: 1rem;
        }
        &.top-0-5n {
          top: -0.5rem;
        }
        &.top-1-5 {
          top: 1.5rem;
        }
        &.top-2 {
          top: 2rem;
        }
        &.top-3 {
          top: 3rem;
        }
      }

      .mypage-btn {
        width: auto !important;
        margin-right: 2rem;
      }
    }
  }


  #deactivation-buttons-area {
    .success-message-container,
    .error-message-container {
      display: none;
      &.show {
        display: inherit;
      }
    }
    .ajax-submit-indicator {
      color: $primary;
    }
  }

  #location-map {
    height:250px;
  }

  &.editor-locked {
    input, textarea, .form-control, .checkbox-switch-slider, .custom-checkbox {
      pointer-events:none !important;
      cursor: not-allowed !important;
    }
    .custom-checkbox {
      .custom-control-label:before {
        background-color:#F5F5F5;
      }
    }
    .select2-selection, .checkbox-switch-slider {
      background-color:#F5F5F5;
    }
    .fake-input-button {
      background-color: #AAA;
    }
    input, textarea, .fake-input, .select2 {
      pointer-events:none;
      color:#AAA;
      background-color:#F5F5F5;
      cursor: not-allowed !important;
    }
    button {
      pointer-events:none;
      cursor: not-allowed !important;
    }
  }

}

