.news-page-container {
  margin-top: 3rem;

  .select2 {
    width: 100% !important;
  }

  .ajax-change-indicator {
    padding-bottom: 1rem;
  }

  .news-filter-title,
  .news-filter-text {
    margin-bottom: 3rem;
  }

  .news-filter-title {
    display: inline-block;
    font-weight: $font-weight-light;
  }
}

.news-detail {
  margin-top: 3rem;

  h1,
  h2 {
    margin-bottom: 0.5rem;
  }

  .news-teaser-text {
    margin-bottom: 3rem;
  }

  .news-entry-authors {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .news-meta-info {
    margin-bottom: 1rem;

    .badge-date {
      font-weight: $font-weight-regular;
    }
  }
}

@include media-breakpoint-down(sm) {
  .news-category {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .userreport-btn {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
