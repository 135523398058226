.slick-slider-thumbs {
  .slick-track {
    height: 3rem;
  }
}

.slick-slider {
  .slick-arrow {
    z-index: 1;
  }
}

.slick-slide {
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    height: auto;
  }
}
