.select2-container--bootstrap {

  .select2-dropdown,
  .select2-selection {
    border-radius: $border-radius;
  }

  .select2-dropdown--above,
  .select2-selection,
  &.select2-container--focus .select2-selection,
  &.select2-container--open .select2-selection {
    box-shadow: none;

    &.is-valid {
      border-color: $color-schwind-green;
    }

    &.is-invalid {
      border-color: $danger;
    }
  }

  .select2-dropdown,
  &.select2-container--focus .select2-selection,
  &.select2-container--open .select2-selection {
    border-color: $primary;

    &.is-valid {
      border-color: $color-schwind-green;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
  }

  .select2-search--dropdown .select2-search__field {
    box-shadow: none;
    border-radius: $border-radius;

    &:focus {
      border-color: $primary;
    }
  }

  &.select2-container--open .select2-dropdown {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .select2-selection__clear {
    line-height: 1.25rem;
  }

  .select2-selection--single {
    font-size: 1rem;
    line-height: 1.5;
    height: 2.375rem;

    .select2-selection__arrow {
      position: absolute;
      right: 1.25rem;
      top: 50%;
      bottom: auto;
      line-height: 0;

      &:before,
      &:after {
        position: absolute;
        @include swi-icon;
        font-size: 0.9rem;
        color: $primary;
      }

      &:before {
        top: -0.33rem;
        content: $swi-chevron-up;
      }

      &:after {
        top: 0.33rem;
        content: $swi-chevron-down;
      }

      b {
        display: none;
      }
    }
  }
}

// Fix for select form error messages before dropdown


.was-validated {
  .select2-init.is-invalid {
    + .select2-container .select2-selection {
      border-color: $danger;
    }
    + .invalid-feedback {

      + .select2-container .select2-selection {
        border-color: $danger;
      }
    }
  }
}
/*
 * ------------
 * Multiselects
 * ------------
 */
.select2-multiselect-styling-fix {


  .select2-selection__rendered {
    // Padding for the dropdown symbol
    padding-right: 30px !important;

    .select2-selection__choice {
      white-space: normal;


      //Make close icon bigger, and show it right not left
      .select2-selection__choice__remove {
        float: right;
        margin-left: 0.7rem;
        font-size: 1.5rem;
        line-height: 0.6;
        font-weight: normal;
      }

    }
  }

  //Adds a dropdown symbol
  .select2-selection--multiple {
    &::before,
    &::after {
      position: absolute;
      /* stylelint-disable */
      font-family: schwindicons !important;
      /* stylelint-enable */
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: .9rem;
      color: #3094b4;
      right: 10px;
    }

    &::before {
      top: 0.75rem;
      content: "\E96F";
    }

    &::after {
      top: 1.5rem;
      content: "\E95A";
    }
  }

}
