@use "sass:math";

.toolbox-custom-thumb-gallery {
  padding-left: 0;
  padding-right: 0;

  .gallery-thumb {
    width: 100%;
  }

  .thumb-gallery-row {
    margin-bottom: - math.div($grid-gutter-width, 2);
    margin-top: - math.div($grid-gutter-width, 2);
  }

  .thumb-gallery-column {
    padding-top: math.div($grid-gutter-width, 2);
    padding-bottom: math.div($grid-gutter-width, 2);
  }
}
