@use "sass:math";

.representatives-select {
  min-width: 200px;
}

.representatives-list {
  background-color: $gray-100;
  margin-top: 5rem;
  padding: 2.25rem 0;

  &.is-empty {
    display: none;
  }
}

.representative-entry {
  @include make-container;
  @include make-container-max-widths;

  display: none;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    > .row > div {
      &:first-child {
        padding: 0;
      }
    }
  }
}

.representative-contacts {
  margin: 0 auto;
  padding: 0 7.5px;
}

.representative-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 1rem;
  background-color: $white;

  @include ie10up {
    display: block;
  }
}

.representative-logo {
  max-width: 100%;
  height: auto;

  // IE 10 and up
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 100%;
    height: auto;
    @include transform(translateY(25%));
  }
  // Edge 12 - 16
  @supports (-ms-accelerator:true) {
    max-width: 100%;
    height: auto;
  }
  // Edge 16 and up
  @supports (-ms-ime-align:auto) {
    max-width: 100%;
    height: auto;
  }
}

.representative-contact-wrapper {
  text-align: center;
  margin: 0 auto;
  width: 100%;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.representative-contact {
  text-align: center;
  max-width: 50vw;
  margin-bottom: 2rem;
  vertical-align: top;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    text-align: left;
    max-width: 9rem;
    padding: 0 math.div($grid-gutter-width, 4);

    img {
      margin: 0 0 0.5rem 0;
    }
  }
}

#modal-consultation {
  textarea {
    min-height: 12rem;
  }
}
