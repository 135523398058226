.any-questions {
  display: table;
  width: 100%;
  background-color: #F5F7F9;

  &-inner {
    display: table-row;

    .any-questions-column {
      display: table-cell;
      vertical-align: top;

      &.icon-column {
        padding: 1.5rem 0.5rem 1.5rem 1.5rem;

        .contact-icon {
          font-size: 3rem;
          color: $color-schwind-lightblue;
          line-height: 1;
        }
      }

      &.button-column {
        width: 100%;
        padding: 1.5rem 1.5rem 1.5rem 0.5rem;

        p {
          margin-bottom: 0;
        }

        .btn {
          margin-top: 1rem;
        }
      }
    }
  }
}
