#CybotCookiebotDialog {
  box-shadow: #121212 2px 4px 10px 1px !important;
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    font-weight: 500 !important;
  }
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {

  font-family: $font-family-sans-serif !important;

}



.video-content,
.toolbox-custom-youtube-video,
.mypage-video  {
  /*
    See Twig AssetExtension, Page Statements
    See also YouTube Video in Bricks bundle
  */
  .cookieconsent-optout-marketing {
    float: left;
    font-size: 0.8rem;
    font-family: $font-family-sans-serif !important;
    .cookieconsent-optout-marketing-video-outter {
      position: absolute;
      background-color: #edf9fd;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .cookieconsent-optout-marketing-video-inner {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
    .cookieconsent-optout-marketing-video-inner-title {
      padding: 6px;
      font-weight: 600;
    }
  }
}

.mypage-video {
  .cookieconsent-optout-marketing {
    min-height: 300px;
    float: none;
  }
}


@include media-breakpoint-down(md) {
  .cookieconsent-optout-marketing {
    min-height: 300px;
  }
}
@include media-breakpoint-down(sm) {
  .cookieconsent-optout-marketing {
    min-height: 200px;
  }
}




.col-xl-4 {

  .toolbox-custom-youtube-video .cookieconsent-optout-marketing {
    font-size: 0.7rem;
    line-height: 1.1;
  }
}

