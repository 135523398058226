.container-primary {
  background-color: $container-primary-bg;
}

.container-primary-inner-secondary {
  background-color: $container-primary-bg;

  .container-inner {
    background-color: $container-secondary-bg;
    padding: $grid-gutter-width;
  }
}

.container-secondary {
  background-color: $container-secondary-bg;
}

.container-secondary-inner-primary {
  background-color: $container-secondary-bg;

  .container-inner {
    background-color: $container-primary-bg;
    padding: $grid-gutter-width;
  }
}

.container-wrapper.has-background {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body[data-noedit] .container-centered-content {
  text-align: center;
}
