.profile {
  margin: 4rem 0;

  .col1 {
    padding-left: 15px;

    img {
      width: 100%;
    }

    .field-title {
      margin-top: 1.5rem;
    }
  }

  .profile-title {
    margin-bottom: 1.5rem;

    .title {
      color: $primary;
    }
  }

  .right-content {
    margin-top: 2rem;
  }

  .field-content {
    margin-bottom: 1.5rem;

    &.email {
      a {
        color: $black;
      }
    }
  }

  .text-right {
    padding-right: 15px !important;
  }

  @include media-breakpoint-up(md) {
    .col {
      padding: 0 1.5rem;
    }

    .right-content {
      margin-top: 0;
    }

    .field-content {
      margin-bottom: 2rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .col {
      padding: 0 3rem;
    }

    .field-content {
      margin-bottom: 3rem;
    }
  }
}
