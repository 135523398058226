.dashboard-intro {
  padding-bottom: 2.5rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.dashboard,
.dashboard-content {
  .webinar-list-entry {
    .dashboard-webinar-link {
      color: $black;
    }
  }

  .toolbox-custom-image-teaser img {
    margin-bottom: 0;
  }

  a.show-mobile {
    display: none;
    margin-top: 1.5rem;
    color: $white;
  }

  .toolbox-custom-image-teaser.show-on-mobile {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .dashboard-content {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .dashboard-content {

    .webinar-list-entry {
      .dashboard-webinar-link {
        color: $color-schwind-lightblue;
      }

      .event-list-btn-container {
        margin: 0.5rem 0;
      }
    }

    a.show-mobile {
      display: block;
    }
  }

  .dashboard {
    .toolbox-custom-media-list {
      display: none;
    }

    .toolbox-custom-image-teaser.hide-on-mobile {
      display: none;
    }

    .toolbox-custom-image-teaser.show-on-mobile {
      display: block;
    }
  }

  .dashboard,
  .dashboard-content {
    .toolbox-columns + .toolbox-spacer,
    .toolbox-custom-background-brick + .toolbox-spacer {
      height: 1rem;
    }

    .toolbox-columns{
      .col-12.col-md-6 + .col-12.col-md-6 {
        margin-top: 1rem;
      }
    }
  }
}
