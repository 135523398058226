@include keyframes(swi-spinning) {
  0% {
    @include transform(rotate(0deg));
  }

  100% {
    @include transform(rotate(360deg));
  }
}

%is-spinning {
  @include animation(swi-spinning 2s infinite linear);
}

.swi-spinning {
  display: inline-block;
  @extend %is-spinning;
}


@include keyframes(swi-spinning-backward) {
  0% {
    @include transform(rotate(360deg));
  }

  100% {
    @include transform(rotate(0deg));
  }
}

%is-spinning-backward {
  @include animation(swi-spinning-backward 2s infinite linear);
}

.swi-spinning-backward {
  display: inline-block;
  @extend %is-spinning-backward;
}
