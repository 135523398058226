.error-page {
  .container {
    height: 825px;
    padding-top: 7rem;
    background-position: bottom right;
    background-repeat: no-repeat;

    .error-page-title {
      color: $primary;
    }

    .error-page-content {
      max-width: 55%;
    }

    .error-page-input {
      max-width: 75%;
    }

    .btn {
      color: $white;
    }

    @include media-breakpoint-down(md) {
      height: 850px;
      background-size: contain;

      .error-page-content {
        max-width: 100%;
      }

      .error-page-input {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      height: 725px;
      padding-top: 5rem;
    }

    @include media-breakpoint-down(xs) {
      height: 670px;
      padding-top: 4rem;
    }
  }
}
