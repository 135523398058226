@use "sass:map";

%auto-hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

%h1 {
  @extend %auto-hyphens;

  font-size: $h1-fontsize;
  font-weight: $font-weight-regular;
  line-height: $h1-lineheight;
  margin-bottom: $h1-margin-bottom;
  color: $h1-color;

  &.secondary {
    color: $h1-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h1-mobile-fontsize;
    line-height: $h1-mobile-lineheight;
  }
}

%h2 {
  @extend %auto-hyphens;

  font-size: $h2-fontsize;
  font-weight: $font-weight-regular;
  line-height: $h2-lineheight;
  margin-bottom: $h2-margin-bottom;
  color: $h2-color;

  &.secondary {
    color: $h2-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h2-mobile-fontsize;
    line-height: $h2-mobile-lineheight;
  }
}

%h3 {
  @extend %auto-hyphens;

  font-size: $h3-fontsize;
  font-weight: $font-weight-medium;
  line-height: $h3-lineheight;
  margin-bottom: $h3-margin-bottom;
  color: $h3-color;

  &.secondary {
    color: $h2-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h3-mobile-fontsize;
    line-height: $h3-mobile-lineheight;
  }
}

%h4 {
  @extend %auto-hyphens;

  font-size: $h4-fontsize;
  font-weight: $font-weight-medium;
  line-height: $h4-lineheight;
  margin-bottom: $h4-margin-bottom;
  color: $h4-color;

  &.secondary {
    color: $h4-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h4-mobile-fontsize;
    line-height: $h4-mobile-lineheight;
  }
}

%h5 {
  @extend %auto-hyphens;

  font-size: $h5-fontsize;
  font-weight: $font-weight-medium;
  line-height: $h5-lineheight;
  margin-bottom: $h5-margin-bottom;
  color: $h5-color;

  &.secondary {
    color: $h5-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h5-mobile-fontsize;
    line-height: $h5-mobile-lineheight;
  }
}

%h6 {
  @extend %auto-hyphens;

  font-size: $h6-fontsize;
  line-height: $h6-lineheight;
  margin-bottom: $h6-margin-bottom;
  color: $h6-color;

  &.secondary {
    color: $h6-color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h6-mobile-fontsize;
    line-height: $h6-mobile-lineheight;
  }
}

%text {
  font-size: $p-fontsize;
  line-height: $p-lineheight;
  font-weight: $p-fontweight;

  @include media-breakpoint-down(sm) {
    font-size: $p-mobile-fontsize;
    line-height: $p-mobile-lineheight;
  }

  @each $size in $p-sizes {
    &.text-#{$size} {
      font-size: map.get($p-fontsizes, $size);
      line-height: map.get($p-lineheights, $size);
      font-weight: map.get($p-fontweights, $size);

      @include media-breakpoint-down(sm) {
        font-size: map.get($p-mobile-fontsizes, $size);
        line-height: map.get($p-mobile-lineheights, $size);
      }
    }
  }
}

%text-lg {
  font-size: map.get($p-fontsizes, lg);
  line-height: map.get($p-lineheights, lg);
  font-weight: map.get($p-fontweights, lg);

  @include media-breakpoint-down(sm) {
    font-size: map.get($p-mobile-fontsizes, lg);
    line-height: map.get($p-mobile-lineheights, lg);
  }
}

%text-md {
  font-size: map.get($p-fontsizes, md);
  line-height: map.get($p-lineheights, md);
  font-weight: map.get($p-fontweights, md);

  @include media-breakpoint-down(sm) {
    font-size: map.get($p-mobile-fontsizes, md);
    line-height: map.get($p-mobile-lineheights, md);
  }
}

%text-sm {
  font-size: map.get($p-fontsizes, sm);
  line-height: map.get($p-lineheights, sm);
  font-weight: map.get($p-fontweights, sm);

  @include media-breakpoint-down(sm) {
    font-size: map.get($p-mobile-fontsizes, sm);
    line-height: map.get($p-mobile-lineheights, sm);
  }
}

%text-xs {
  font-size: map.get($p-fontsizes, xs);
  line-height: map.get($p-lineheights, xs);
  font-weight: map.get($p-fontweights, xs);

  @include media-breakpoint-down(sm) {
    font-size: map.get($p-mobile-fontsizes, xs);
    line-height: map.get($p-mobile-lineheights, xs);
  }
}

%text-xxs {
  font-size: map.get($p-fontsizes, xxs);
  line-height: map.get($p-lineheights, xxs);
  font-weight: map.get($p-fontweights, xxs);

  @include media-breakpoint-down(sm) {
    font-size: map.get($p-mobile-fontsizes, xxs);
    line-height: map.get($p-mobile-lineheights, xxs);
  }
}

%paragraph {
  font-size: $p-fontsize;
  line-height: $p-lineheight;
  color: $p-color;
  margin-bottom: $p-margin-bottom;
  font-weight: $p-fontweight;

  @include media-breakpoint-down(sm) {
    font-size: $p-mobile-fontsize;
    line-height: $p-mobile-lineheight;
  }

  @each $size in $p-sizes {
    &.text-#{$size} {
      font-size: map.get($p-fontsizes, $size);
      line-height: map.get($p-lineheights, $size);
      color: map.get($p-colors, $size);
      margin-bottom: map.get($p-margins-bottom, $size);
      font-weight: map.get($p-fontweights, $size);

      @include media-breakpoint-down(sm) {
        font-size: map.get($p-mobile-fontsizes, $size);
        line-height: map.get($p-mobile-lineheights, $size);
      }
    }
  }
}

%list {
  @each $size in $p-sizes {
    &.list-#{$size} {
      font-size: map.get($p-fontsizes, $size);
      line-height: map.get($p-lineheights, $size);
      color: map.get($p-colors, $size);
      margin-bottom: map.get($p-margins-bottom, $size);
      font-weight: map.get($p-fontweights, $size);

      @include media-breakpoint-down(sm) {
        font-size: map.get($p-mobile-fontsizes, $size);
        line-height: map.get($p-mobile-lineheights, $size);
      }
    }
  }
}
