.toolbox-custom-media-list {

  .media-list-item {
    position: relative;
    padding: 0.5rem 0;

    .media-item-title {
      padding: 0 110px 0 0 !important;
      margin-bottom: 0.5rem;
    }

    .media-item-text {
      padding: 0 !important;
    }

    .media-item-image-container {
      display: inline-block;
      position: absolute;
      top: 15px;
      width: 45px;
      height: 45px;

      .media-item-image {
        width: 100%;
        max-width: 45px;
        display: inline-block;
      }

      .medialist-placeholder-img {
        max-width: 45px;
        display: inline-block;
        @include transform(scale(2.5));
      }
    }

    .right-column {
      display: inline-block;
      width: 100%;
      margin-left: 75px;
      min-height: 45px;
    }

    .swi-download {
      color: $primary;
    }
  }
}
