@use "sass:math";

.custom-checkbox {
  @extend %text-xs;

  //min-height: 2rem;
  margin-bottom: 0.5rem;
  padding-left: 2rem;

  .custom-control-label {
    &:before,
    &:after {
      top: 0;
      left: -2rem;
      width: 1.25rem;
      height: 1.25rem;
    }
    div {
      //=WYSIWYG
      line-height: 1;
    }
  }

  .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label:before {
    background-color: $slider-bg;
    border: 1px solid $slider-border-color;
    border-radius: $slider-border-radius;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 2px 2px rgba($slider-border-focused, 0.5);
  }
}

$slider-width: $slider-inner-width + ($slider-padding * 2);
$slider-height: $slider-inner-height + ($slider-padding * 2);
$slider-handle-size: math.div($slider-width, 2) - $slider-padding - 1px;

.checkbox-switch {
  position: relative;
  display: inline-block;
  line-height: 1.75;
  margin-bottom: 0.5rem;

  .checkbox-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: $slider-width;
    height: $slider-height;
    margin: 0;
    background-color: $slider-bg;
    border: 1px solid $slider-border-color;
    @include box-sizing(content-box);
    @include transition(all 0.4s ease);
    border-radius: $slider-border-radius;

    &:before {
      position: absolute;
      content: "";
      width: $slider-handle-size;
      height: $slider-handle-size;
      top: 50%;
      background-color: $slider-border-color;
      @include translate($slider-padding, -50%);
      @include transition(all 0.4s ease);
      border-radius: $slider-border-radius;
    }
  }

  .checkbox-switch-label,
  span.validation {
    @extend %text-xs;

    margin-left: $slider-width + 16px;
    vertical-align: super;
  }

  .checkbox-switch-label {
    margin-bottom: 0;

    &:before,
    &:after {
      content: none;
    }

    div {
      //=WYSIWYG
      line-height: 1;
    }
  }

  input[type="checkbox"] {
    /* stylelint-disable scss/at-extend-no-missing-placeholder */
    @extend .sr-only;
    /* stylelint-enable scss/at-extend-no-missing-placeholder */

    /* stylelint-disable no-descending-specificity */
    &:checked + .checkbox-switch-slider,
    &:checked + .validation + .checkbox-switch-slider {
      background-color: $slider-bg-active;
      border: 1px solid $slider-border-color-active;

      &:before {
        @include transform(translate(math.div($slider-width, 2) + 1px, -50%));
        background-color: $slider-border-color-active;
      }
    }

    &:focus {
      + .checkbox-switch-slider,
      + .validation + .checkbox-switch-slider {
        box-shadow: 0 0 2px 2px rgba($slider-border-focused, 0.5);
      }
    }
    /* stylelint-enable no-descending-specificity */
  }
}

.was-validated {
  .checkbox-switch {

    input[type="checkbox"] {
      &.is-invalid {
        + .checkbox-switch-slider,
        + .validation + .checkbox-switch-slider {
          border-color: $danger;

          &:before {
            background-color: $danger;
          }
        }

        &:focus {
          + .checkbox-switch-slider,
          + .validation + .checkbox-switch-slider {
            box-shadow: 0 0 2px 2px rgba($danger, 0.5);
          }
        }

        /* stylelint-disable no-descending-specificity */
        &:valid {
          + .checkbox-switch-slider,
          + .validation + .checkbox-switch-slider {
            border-color: $success;
            box-shadow: 0 0 2px 2px rgba($success, 0.5);

            &:before {
              background-color: $success;
            }
          }
        }
        /* stylelint-enable no-descending-specificity */
      }
    }
  }


  /*
   * The code below fixes problem that if checkbox label is a WYSIWYG area,
   * then WYSIWYG colors override validation colors
   */

  .checkbox-switch:invalid ~ .custom-control-label, .checkbox-switch.is-invalid ~ .custom-control-label {
    color: $color-schwind-orange !important;
    .color-darkgray {
      color: $color-schwind-orange !important;
    }
    a {
      color: $color-schwind-lightblue;
    }

  }

  .checkbox-switch:valid ~ .custom-control-label, .checkbox-switch.is-valid ~ .custom-control-label {
    color: $color-schwind-green !important;
    .color-darkgray {
      color: $color-schwind-green !important;
    }
    a {
      color: $color-schwind-lightblue;
    }
  }
}

/*
 * Show checkbox invalid without validating the whole form
 */
#mypage-editor {
  .checkbox-switch {

    input[type="checkbox"] {
      &.is-like-invalid {
        + .checkbox-switch-slider,
        + .validation + .checkbox-switch-slider {
          border-color: $danger !important;

          &:before {
            background-color: $danger !important;
          }
        }
      }
    }
  }
}
