.trainingcenter-detail {
  .description {
    font-size: $font-size-lg;
  }

  h1 {
    margin-bottom: $space-2;
  }

  .event-meta-data {
    .badge {
      @extend %text-xs;
      padding-left: 0;
      padding-right: 2rem;
      font-weight: $font-weight-medium;

      &.badge-date,
      &.badge-time,
      &.badge-download {
        @extend %text-md;
        font-weight: $font-weight-medium;

        .swi {
          font-size: 1.5rem;
        }
      }
    }
  }

  .feeback-area {
    display: table;
    width: 100%;
    .feedback-area-inner {
      display: table-row;
    }
    background-color: $container-secondary-bg;
    .feedback-column {
      display:table-cell;
      vertical-align: top;
    }
    .icon-column {
      padding: 1.5rem 0.5rem 1.5rem 1.5rem;
    }
    .contact-icon {
      font-size: 3rem;
      color: $color-schwind-lightblue;
      line-height: 1;
    }
    .button-column {
      width: 100%;
      padding: 1.5rem 1.5rem 1.5rem 0.5rem;
    }
  }
}


.gray-area {
  background-color: $container-secondary-bg;
}

.dashboard-content {
  padding-top: 4rem;
}

.training-and-webinar-wysiwyg {
  background-color: $white;
  padding: 1rem;
}

.webinar-list-entry {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  background-color: $white;
  display: flex;
  align-items: center;

  .text {
    line-height: 2.2;
  }

  .mb-space-1-2 {
    margin-bottom: 0;
  }

  .event-list-btn-container {
    float: right;

    @include media-breakpoint-down(sm) {
      float: left;
    }
  }
}


#trainingcenter-load-more-list {
  margin-top: $space-2;

  .select2.select2-container {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .section-title {
    padding: 0.3rem 0.75rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;


  }

  .trainingcenter-list-entry {
    padding-top: $space-2;
    padding-bottom: $space-2;

    &+.section-title-row {
      margin-top: $space-4;
    }

    +.trainingcenter-list-entry {
      border-top: 1px solid $color-separator;
    }

    .event-list-btn-container {
      padding-top: $space-1-2;
    }

    .date-column {
      padding-left: 15px;
    }

    @include media-breakpoint-up(md) {
      .event-list-btn-container {
        position: relative;
        text-align: right;
        height: 100%;

        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    @include media-breakpoint-down(md) {
      .text-column {
        padding-left: 15px;
      }
      .event-list-btn-container {
        padding-left: 15px;
      }
    }
  }
}

#trainingcenter-recordings-load-more-list {
  .recordings-list-entry {
    margin-bottom: $space-1;

    .recordings-list-entry-inner {
      padding: $space-1;
      background-color: $container-primary-bg;
    }

    @include media-breakpoint-up(lg) {
      .recording-properties {
        .text {
          display: inline-block;
          vertical-align: top;
          padding-top: 3px;
        }
      }
    }
  }
}
