html,
body {
  height: 100%;
}

body {
  display: block;
  display: flex;
  flex-direction: column;
}

#page {
  flex: 1 0 0;
}


video {
  display: block;
}

.text {
  @extend %text;
}

p {
  @extend %paragraph;
}

ul,
ol {
  @extend %list;
}

label[for] {
  cursor: pointer;
}

hr {
  &.default {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &.medium {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &.large {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.background-primary {
  background-color: $white;
}

.background-secondary {
  background-color: $container-secondary-bg;
}

.margin-auto-top-bottom {
  margin-top: auto;
  margin-bottom: auto;
}

.img-responsive {
  width: 100%;
  height: auto;
}


.cursor-as-link {
  cursor: pointer;
}

.link-color {
  color: $link-color;
}


h1,
.like-h1 {
  @extend %h1;
}

h2,
.like-h2 {
  @extend %h2;
}

h3,
.like-h3 {
  @extend %h3;
}

h4,
.like-h4 {
  @extend %h4;
}

h5,
.like-h5 {
  @extend %h5;
}

h6,
.like-h6 {
  @extend %h6;
}

h2.like-title-startpage {
  font-weight: 300;
  font-size: 1.625rem;
}

.color-lightblue {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6 {
    color: $color-schwind-lightblue;
  }
}

.color-blue {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6 {
    color: $color-schwind-blue;
  }
}

.color-darkblue {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6
  {
    color: $color-schwind-darkblue;
  }
}

.color-white {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6
  {
    color: $color-schwind-white;
  }
}

.color-black {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6
  {
    color: $color-schwind-black;
  }
}

.color-darkgray {
  /* For WYSIWYG editor */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .like-h1,
  .like-h2,
  .like-h3,
  .like-h4,
  .like-h5,
  .like-h6
  {
    color: $color-schwind-darkgray;
  }
}

/* stylelint-disable no-descending-specificity */
h1,
h2,
h3,
h4,
h5,
h6,
.like-h1,
.like-h2,
.like-h3,
.like-h4,
.like-h5,
.like-h6,
p,
div,
span,
.text-xxs.text,
.text-xs.text,
.text-sm.text,
.text-md.text,
.text-lg.text,
a {
  .color-lightblue,
  &.color-lightblue {
    color: $color-schwind-lightblue;
  }

  .color-blue,
  &.color-blue {
    color: $color-schwind-blue;
  }

  .color-darkblue,
  &.color-darkblue {
    color: $color-schwind-darkblue;
  }

  .color-black,
  &.color-black {
    color: $color-schwind-black;
  }

  .color-darkgray,
  &.color-darkgray {
    color: $color-schwind-darkgray;
  }

  .color-white,
  &.color-white {
    color: $color-schwind-white;
  }

  .color-danger,
  &.color-danger {
    color: $danger;
  }

  .bold,
  &.bold {
    font-weight: $font-weight-bold;
  }

  .medium,
  &.medium {
    font-weight: $font-weight-medium;
  }

  .regular,
  &.regular {
    font-weight: $font-weight-regular;
  }

  .light,
  &.light {
    font-weight: $font-weight-light;
  }

  .thin,
  &.thin {
    font-weight: $font-weight-thin;
  }
}

/* stylelint-enable no-descending-specificity */

span {
  &.bold {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    p,
    div,
    span,
    .text-xxs.text,
    .text-xs.text,
    .text-sm.text,
    .text-md.text,
    .text-lg.text,
    a {
      font-weight: $font-weight-bold;
    }

  }
  &.medium {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    p,
    div,
    span,
    .text-xxs.text,
    .text-xs.text,
    .text-sm.text,
    .text-md.text,
    .text-lg.text,
    a {
      font-weight: $font-weight-medium;
    }
  }
  &.regular {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    p,
    div,
    span,
    .text-xxs.text,
    .text-xs.text,
    .text-sm.text,
    .text-md.text,
    .text-lg.text,
    a {
      font-weight: $font-weight-regular;
    }
  }
  &.light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    p,
    div,
    span,
    .text-xxs.text,
    .text-xs.text,
    .text-sm.text,
    .text-md.text,
    .text-lg.text,
    a {
      font-weight: $font-weight-light;
    }
  }
  &.thin {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .like-h1,
    .like-h2,
    .like-h3,
    .like-h4,
    .like-h5,
    .like-h6,
    p,
    div,
    span,
    .text-xxs.text,
    .text-xs.text,
    .text-sm.text,
    .text-md.text,
    .text-lg.text,
    a {
      font-weight: $font-weight-thin;
    }
  }
  &.phone-number {
    &:before {
      @include swi-icon;
      content: $swi-phone;
      padding-right: 0.3em;
    }
  }
  &.fax-number {
    &:before {
      @include swi-icon;
      content: $swi-print;
      padding-right: 0.3em;
    }
  }
  &.legal-text {
    font-size: 12px;
    line-height: 16px;
    color: $color-schwind-darkgray;
  }
}



.image-hover-link[href] {
  & > img:hover,
  & > picture:hover {
    background-color: $white;
    opacity: 0.3;
    @include transition(all 0.3s ease);
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    display: block;
  }
}
