label,.form-control-label {
  font-size: 0.75rem;

  &.required:after {
    content: " *";
  }
}



.form-builder-wrapper {
  .form-field-customer-number-readonly {
    border: none;
    background-color: white;
    font-weight: $font-weight-regular;
    padding: 0;
  }

  form {
    .default.fb-cl-hide-element {
      display: none !important;
    }

    label.required:after { content:" *"; }

    legend {
      &.required:after {
        content: " *";
      }
    }

    .custom-radio {
      label:after {
        content: "";
      }
    }
  }
  form > .row {
    margin: 0 -15px 0 0;

    > .default {
      padding-right: 15px;
      padding-bottom: 0.25rem;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 33%;
      }

      &.form-fullwidth {
        width: 100%;
      }

      &.form-halfwidth {
        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      &.form-quarterwidth {
        @include media-breakpoint-up(md) {
          width: 50%;
        }

        @include media-breakpoint-up(lg) {
          width: 25%;
        }
      }

      &.form-thirdwidth {
        @include media-breakpoint-up(md) {
          width: 50%;
        }

        @include media-breakpoint-up(lg) {
          width: 33.3333%;
        }
      }

      &.form-fifthwidth {
        @include media-breakpoint-up(md) {
          width: 33.3333%;
        }

        @include media-breakpoint-up(lg) {
          width: 20%;
        }
      }

      &.form-twofifthswidth {
        @include media-breakpoint-up(md) {
          width: 33.3333%;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }
      }

      &.form-threefifthswidth {
        @include media-breakpoint-up(md) {
          width: 66.6666%;
        }

        @include media-breakpoint-up(lg) {
          width: 60%;
        }
      }
    }
  }
}


.form-breaker {
  display: block;
  width: 100%;
}

.formhandler-error-message-container {
  display: none;
  &.is-editmode,
  &.is-form-fatal-error {
    display: block;
  }
}
