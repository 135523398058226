/* stylelint-disable  scss/at-extend-no-missing-placeholder */
%text-super {
  @extend sup;
}
%text-sub {
  @extend sub;
}
/* stylelint-enable  scss/at-extend-no-missing-placeholder */

.text-super {
  @extend %text-super;
}

.text-sub {
  @extend %text-sub;
}

.quoted {
  &:before,
  &:after {
    content: "\"";
  }

  &:before {
    margin-right: -4px;
  }

  &:after {
    margin-left: -4px;
  }
}
