$badge-padding-y: 0.25rem;
$badge-padding-x: 0.33rem;
$badge-border-radius: 1px;

$badge-ternary-bg: $gray-700;
$badge-ternary-color: $white;

.badge {
  &.backgroundcolor-lightblue {
    color: white;
    background-color: $color-schwind-lightblue;
  }

  &.backgroundcolor-blue {
    color: white;
    background-color: $color-schwind-blue;
  }

  &.backgroundcolor-darkblue {
    color: white;
    background-color: $color-schwind-darkblue;
  }

  &.backgroundcolor-yellow {
    color: white;
    background-color: $color-schwind-yellow;
  }

  &.backgroundcolor-green {
    color: white;
    background-color: $color-schwind-green;
  }

  &.backgroundcolor-orange {
    color: white;
    background-color: $color-schwind-orange;
  }

  &.backgroundcolor-black {
    color: white;
    background-color: $color-schwind-black;
  }

  &.backgroundcolor-lightgray {
    color: white;
    background-color: $color-schwind-lightgray;
  }

  &.backgroundcolor-darkgray {
    color: white;
    background-color: $color-schwind-darkgray;
  }
}
