/* stylelint-disable no-descending-specificity */
@use "sass:map";

.header-navbar {
  @include make-container;
  @include make-container-max-widths;
  height: $header-height;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    height: $header-mobile-height;
    padding: {
      top: 0;
      right: 21px;
      bottom: 0;
      left: 21px;
    }
  }

  .navbar-toggler {
    outline: none;
  }

  .navbar-collapse {
    margin-top: 0.6rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .navbar-nav {
    background-color: $header-navbar-bg;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }

    .btn {
      @include transition(none);
    }

    > .nav-item {
      overflow: visible;

      > .nav-link {
        @include underline-from-center;
        &.language-switch,
        &.portal-link {
          min-width: 84px;
          white-space: nowrap;
        }

        &.portal-logout {
          min-width: 115px;
          white-space: nowrap;
        }

        &:before {
          background: $color-schwind-lightblue;
        }
      }

      &.active > .nav-link:before {
        left: 0;
        right: 0;
      }
    }
  }

  .navbar-brand {
    display: inline-block;
    height: $header-logo-height;
    width: $header-logo-width;
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;

    &.brand-portal {
      width: $header-logo-width-portal;
    }

    @include media-breakpoint-down(md) {
      height: inherit;
      width: $header-mobile-logo-width;
    }

    // IE 10 and up
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        left: 15px;
      }
    }
  }

  .navbar-menu-container {
    opacity: 1;
    display: none !important;

    @include media-breakpoint-down(md) {
      display: flex !important;
    }

    &.show {
      display: flex !important;
    }
  }

  .no-line:before {
    background: none !important;
  }

  .swi-search {
    padding-left: 0.8rem !important;

    @include media-breakpoint-down(md) {
      font-size: 1rem !important;
    }
  }

  .mobile-search-text {
    display: none;
  }

  .mobile-search-text-active {
    display: none;
  }

  .search-input {
    display: none;
    background-color: $header-search-input-bg;
    border: $header-search-input-border;
    border-radius: $header-search-input-border-radius;
    padding-left: 2.5rem;
  }



  .search-form-dummy {
    width: 0;
    display: none;
  }

  .search-form {
    .search-wrapper {
      width: 100%;

      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }

    &.show {
      width: 0%;
      position: absolute;

      @include media-breakpoint-down(md) {
        width: 100%;
        position: relative;
        opacity: 1;
        right: 0;
      }

      .search-input {
        width: 100%;
        display: inline-block;

        @include media-breakpoint-up(lg) {
          width: 75%;
        }
      }

      .clear-button,
      .search-button {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        padding-top: 0;
        padding-bottom: 4px;

        &:before {
          content: none;
        }
      }

      .clear-button {
        display: inline-block;
        right: 0.25rem;

        @include media-breakpoint-up(lg) {
          top: 1rem;
          right: -1rem;
        }

        .swi {
          font-size: 0.8rem;
        }
      }

      .search-button {
        pointer-events: none;
        left: 1rem;

        @include media-breakpoint-up(lg) {
          left: 22%;
        }

        @include media-breakpoint-up(xl) {
          top: 1rem;
          left: 23%;
        }

        .swi {
          font-size: 0.8rem;
        }
      }

      // IE 10 and up
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 0.75rem;
      }
    }
  }

  .clear-button,
  .search-button,
  .nav-link {
    font-size: $header-navbar-fontsize;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    a {
      text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem !important;
      margin: 0 0.1rem !important;
    }
  }


  .clear-button,
  .search-button {
    padding: 0.5rem 0;
    font-weight: $font-weight-regular;

    &:before {
      height: 4px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.8rem 1rem;
    }
  }

  .clear-button {
    display: none;
  }

  .flag-icon {
    font-size: 0.9rem;
  }

  &:before,
  &:after {
    content: "";
    position: fixed;
    top: 0;
    height: $header-height;
    left: 0;
    right: 0;
    pointer-events: none;

    @include media-breakpoint-down(md) {
      height: $header-mobile-height;
    }
  }

  &:before {
    background-color: $header-navbar-bg;
    z-index: -1;
  }

  &:after {
    $header-max-widths: ();
    @each $max-width-key in map.keys($container-max-widths) {
      $header-max-width: map.get($container-max-widths, $max-width-key) - $grid-gutter-width;
      $header-max-widths: map.merge($header-max-widths, ($max-width-key: $header-max-width));
    }

    @include make-container;
    @include make-container-max-widths($header-max-widths);
    border-bottom: $header-navbar-border-bottom;
  }

  &.flyout-open:after {
    border-bottom: none;
  }

  .dropdown-toggle {
    &:after {
      content: none;
    }
  }

  .dropdown-menu {
    border: $header-dropdown-border;
    @include box-shadow($header-dropdown-box-shadow);

    @include media-breakpoint-up(lg) {
      min-width: 17rem;
      top: $header-height + $megamenu-offset;
    }
  }

  .dropdown-header {
    font-weight: $font-weight-bold;
    text-decoration: none;
  }

  .dropdown-item {
    background-color: $header-dropdown-item-bg;
    color: $header-dropdown-item-color;

    &:hover,
    &:focus,
    &.active,
    &:active {
      background-color: $header-dropdown-item-active-bg;
      color: $header-dropdown-item-active-color;
    }

    &.active {
      font-weight: $font-weight-regular;
      color: $header-dropdown-item-active-color;
    }
  }

  .menu-desktop {
    display: block;
  }

  .menu-mobile {
    display: none;
  }

  .mega-dropdown {
    @include media-breakpoint-up(lg) {
      position: static;
    }

    .mega-menu {
      @include media-breakpoint-up(lg) {
        width: 100%;
        left: 0;
        right: 0;
        top: $header-height + $megamenu-offset;
        padding: 2rem 2rem 2.5rem 2rem;
      }
    }

    .mega-menu-title {
      margin: $megamenu-title-margin;
    }

    .mega-menu-wrapper-link,
    .mega-menu-link {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    .mega-menu-link {
      @extend %text-xs;
      color: $megamenu-link-color;

      &:hover,
      &:focus,
      &:active {
        color: $megamenu-link-active-color;
      }
      &.active {
        color: $megamenu-link-active-color;
      }

      &.link-paddingleft{
        padding-left: 0.75rem;
      }
    }

    .mega-menu-show-all {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      background-color: $megamenu-showall-bg;
      color: $megamenu-showall-color;

      &:after {
        @include swi-icon;
        content: $swi-chevron-right;

        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.75rem;
      }
    }
  }


  &.navbar-light {
    .nav-link,
    .btn .swi {
      &,
      &:hover,
      &:focus,
      &:active {
        color: $header-navlink-color;
      }
    }
  }

  &.navbar-dark {
    &.navbar-docked {
      &:before {
        background-color: $header-navbar-bg-dark;
      }

      &:after {
        border-bottom: none;
      }

      .navbar-toggler {
        color: $white !important;

        .navbar-toggler-icon {
          color: $white;
        }
      }

      .nav-link,
      .btn .swi {
        color: $black;

        @include media-breakpoint-up(lg) {
          color: $header-navlink-color-dark;
        }
      }

      .search-form {
        .search-input {
          @include media-breakpoint-up(lg) {
            background-color: $header-search-input-bg-dark;
            color: $header-search-input-color-dark;

            @include placeholder {
              color: rgba($header-search-input-color-dark, 0.8);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .nav-link.dropdown-toggle {
      &.active {
        color: $header-dropdown-item-active-color;
      }
    }
  }
}

.search-form {
  .swi-cross:before {
    @include media-breakpoint-down(md) {
      color: $gray-700;
      font-size: 14px;
    }
  }
}

body.no-scroll {
  & .header-navbar.navbar-light:after {
    border: none;
  }
}

body.with-light-navbar,
body[data-edit] {
  margin-top: $header-height;

  &.without-distance {
    margin-top: 0;
  }
}

body[data-edit].with-dark-navbar .header-navbar.navbar-docked:before {
  background-color: $header-navbar-bg-dark-editmode;
}

.mega-menu-show-all-mobile {
  display: none !important;
}

@include media-breakpoint-down(md) {

  // mimics dropdown style for non-dropdown items
  .brand-portal ~ .navbar-collapse {
    .navbar-menu-container {
      .nav-item {
        border-bottom: 2px solid $gray-400;

        &:last-child {
          border-bottom: none;
        }

        .nav-link {
          font-size: 1.5rem !important;
        }
      }
    }
  }

  body.no-scroll {
    overflow: hidden;
  }

  .header-navbar {
    .menu-desktop {
      display: none;
    }

    .menu-mobile {
      display: block;
      line-height: 24px;
      padding-top: 5px !important;
    }
  }

  .language-switch {
    margin-top: 1rem;
    padding-left: 0 !important;
  }

  .nav-link {
    text-align: left;
    width: 100%;

    &.dropdown-toggle {
      font-size: 1.5rem !important;
    }

    &.sub-dropdown-toggle {
      font-size: 1.25rem !important;
      padding: 0.1rem 0 !important;
    }

    &:before {
      height: 0px !important;
    }

    &.active {
      color: $color-schwind-lightblue !important;
    }
  }

  .search-button {
    &:before {
      height: 0px !important;
    }
  }

  .nav-item {
    padding-left: 0;
  }

  .navbar-toggler {
    border: none;

    &[aria-expanded="false"] {
      .navbar-toggler-icon {
        background-image: none !important;
        color: #888;

        &:before {
          @include swi-icon;
          content: $swi-hamburger;
          position: absolute;
          top: 35px;
          right: 26px;
        }
      }
    }

    &[aria-expanded="true"] {
      .navbar-toggler-icon {
        background-image: none !important;
        color: #888;

        &:before {
          font-size: 24px;
          @include swi-icon;
          content: $swi-cross;
          position: absolute;
          top: 35px;
          right: 26px;
        }
      }
    }
  }

  .nav-item.show {
    &:before {
      @include swi-icon;
      content: $swi-chevron-up;
      color: $primary;
      position: absolute;
      line-height: 3em;
      top: 0;
      right: 0;
      font-size: 21px;
    }

    .dropdown-toggle {
      color: $color-schwind-blue !important;
    }

    .dropdown-header {
      display: none;
    }

    .mega-menu-show-all-mobile {
      display: block !important;
      font-size: 0.75rem !important;
      padding: 0.5rem 1.5rem 0.5rem 1rem !important;
      margin: 0.5rem 0;
      background-color: $megamenu-showall-bg;
      color: $megamenu-showall-color !important;
      width: 160px;

      &:after {
        @include swi-icon;
        content: $swi-chevron-right;

        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.75rem;
      }
    }

    .mega-menu-show-all {
      display: none;
    }

    .dropdown-menu.show {
      border-top: 2px solid $primary !important;
      box-shadow: none;
      padding-bottom: 1em;

      .dropdown-submenu.show {
        border-top: none !important;
        padding-top: 0.3rem;
      }

      a {
        font-size: 1.1rem;
        color: $black;
        padding: 0.2rem 0;
        &.active {
          color: $header-dropdown-item-active-color;
        }
      }
    }
  }

  .menu-mobile {
    .sub-dropdown-toggle[aria-expanded=true] {
      color: $color-schwind-blue !important;
    }

    .dropdown-item {
      color: $primary !important;
      font-size: 1rem !important;
    }
  }

  .navbar-menu-container {

    @include media-breakpoint-down(md) {
      margin: 0 6px !important;
    }

    .dropdown {
      border-bottom: 2px solid $gray-400;

      &.show {
        border: 0;
      }
    }

    .dropdown:last-Child {
      border: none;
    }
  }

  // Styles for mobile
  .header-navbar.mobile {
    padding: 0 !important;
    max-width: none !important;

    &:after {
      max-width: none;
    }
  }

  .navbar-brand.mobile {
    margin-left: 2rem;
  }

  .navbar-collapse {
    overflow-y: auto;
    max-height: 93vh;
    max-height: calc(100vh - 4.5rem);

    &:before {
      content: '';
      position: fixed;
      top: 3rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      z-index: -1;
    }

    &.show,
    &.collapsing {
      .navbar-menu-container {
        padding: 0 2rem;
        margin: 0 !important;
      }

      .nav-item.menu-mobile {
        padding-left: 2rem;
      }
    }

    .search-form {
      background-color: $white;
      padding-top: 1rem;

      .mobile-search-text-active {
        display: none;
        font-size: 1.125rem;
        margin-left: 3.95rem;
        padding: 0.5rem 0;
        width: 100%;
      }

      button {
        margin-left: 1rem;
      }

      input[type=search] {
        background-color: #E8E8E8;
      }

      .mobile-search-text {
        display: inline-block;
        font-size: 1.125rem;
      }

      .search-button {
        margin-left: 0 !important;
        padding-left: 1.4rem;
      }

      &.show {
        .mobile-search-text {
          display: none;
        }

        .mobile-search-text-active {
          display: block;
        }

        .search-input {
          padding-left: 2rem;
          height: 54px;
        }

        .search-button {
          margin-left: 0 !important;
          left: 0.75rem;
          top: 2.5rem;

          .swi {
            padding-left: 0 !important;
          }
        }

        .clear-button {
          margin-right: 1rem;
          top: 5.4rem;
        }
      }
    }
  }

  .right-nav {
    background-color: $color-schwind-lightgray !important;
    padding-top: 1rem;

    .additional-mobile-container {
      margin-bottom: 2rem;
    }

    .nav-item {
      padding: 1rem 0;
      padding-left: 0.8rem;
      line-height: 1;

      &.is-portal {
        padding: 0.5rem 2rem 0;
      }

      &.additional-mobile {
        padding: 0.5rem 0;

        a {
          font-size: 1.125rem;

          &.active {
            color: $color-schwind-blue;
          }
        }

        .swi {
          margin-right: 0.7rem;

          &.active {
            color: $color-schwind-blue;
          }
        }

        .swi-output {
          display: inline-block;
          @include transform(rotate(180deg));
        }
      }
    }

    .nav-link {
      text-align: left;
      display: inline !important;

      a {
        text-decoration: none;
      }

      span {
        font-size: 1.125rem;
      }

      .swi {
        margin-right: 0.5rem;
        font-size: 1.2rem;
      }

      .flag-icon {
        margin-right: 0.5rem;
      }
    }

    .mobile-nav-socials {
      float: right;
      padding-right: 2rem;

      .swi {
        font-size: 1.5em;
        padding-left: 0.5rem;
        color: $primary !important;
      }
    }
  }
}

.is-portal {

  .portal-flyout-desktop-nav {
    img, span {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
    padding-bottom: 1.5rem;

    .portal-flyout-top {
      padding: 1rem 0;
      background-color: $container-secondary-bg;

      .row {
        margin: 0;

        .profile-image {
          img {
            max-width: 100%;
            height: auto;
          }
        }

        .profile-header {
          padding: 0;
        }
      }
    }

    .dropdown-items-top {
      padding: 1rem 0;
    }

    .dropdown-item-bottom {
      padding-bottom: 0.5rem;

      .swi {
        margin-left: 1.5rem;
        display: inline-block;
        @include transform(rotate(180deg));
        color: $gray-800 !important;
      }

      .dropdown-item.icon {
        display: inline;
        padding-left: 0.5rem;
        color: $gray-800 !important;

        &:hover {
          color: $primary !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.nav-item:before {
      right: 12px;
    }

    .dropdown-menu {
      background-color: $gray-100;

      .profile-header {
        display: flex;
        align-items: center;
      }

      .dropdown-items-top {
        .dropdown-item {
          padding-left: 1.5rem !important;
        }
      }
    }
  }

}

.portal-flyout-desktop {
  display: block;
}
.portal-flyout-mobile {
  display: none;
}
.ext-link {
  display: inline;
}

@media only screen and (max-width: 1425px) {
  .portal-flyout {
    left: -11rem;
  }
}

@include media-breakpoint-down(md) {
  .navbar-collapse {
    margin-top: 0;
  }
  .portal-flyout-desktop {
    display: none;
  }
  .portal-flyout-mobile {
    display: block;

    .profile-image {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .dropdown-items-top {

      .account {
        float: left;
        padding: 1.5rem 0;
      }

      .logout {
        float: right;
        padding: 1.5rem 0;
      }

      .account,
      .logout {
        .color-lightblue{
          color: $color-schwind-lightblue !important;
        }
      }
    }

    .dropdown-item-bottom {
      padding-left: 2rem;
    }
  }

  .portal-right-nav {
    margin-top: 1.5rem;
  }
}

#flyout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($black, 0.2);
  z-index: 100;
  @include animation(fadeIn);
}
/* stylelint-enable no-descending-specificity */
