.account-edit-form {

  .upload-image-ajax-change-indicator {
    display: none;
    &.show {
      display: block;
    }
  }

  .red-info-label {
    color: $danger;
    line-height: 1;
  }

  .accept-photo-terms-text {
    line-height: 0.85rem;
    font-size: 0.65rem;
    div, p {
      line-height:  0.85rem;
      font-size: 0.65rem;
      color: $color-schwind-darkgray;
    }
  }

  .hidden {
    display: none;
  }

  .custom-file-container__image-preview {
    width: 200px;
    height: 200px;
    background-color: $container-secondary-bg;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .custom-file-container__image-multi-preview {
      max-width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .max-width-200 {
    max-width: 200px !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  .btn-upload-col-left {
    padding: 0 0.5rem 0 0 ;
  }

  .btn-upload-col-right {
    padding: 0 0 0 0.5rem;
  }


  .photo-terms-container {
    .show-on-warning {
      display: none;
    }

    &.show-warning {
      .show-on-warning {
        display: block;
      }
    }

  }
}
