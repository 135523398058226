.toolbox-columns {
  .toolbox-column {
    .toolbox-custom-social-media-box {
      min-height: 250px;

      &.fullheight {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;

        @include media-breakpoint-down(xs) {
          position: relative;
        }

        .gray-networking-area-btn {
          position: absolute;
          bottom: 3rem;
        }
      }
    }
  }
}
