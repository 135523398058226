.toolbox-custom-icon-row {
  .icon-column {
    text-align: center;
    margin: 0.5rem 0 1.5rem 0;
    line-height: 3;
  }

  .icon-row-icon,
  .icon-row-text p {
    font-weight: $font-weight-light;
  }

  .icon-row-icon {
    @extend %h4;
    font-size: 1.75rem;
    margin-right: 0.5rem;
  }

  .icon-row-text {
    display: inline;

    p {
      @extend %h4;
      display: inline-block;
    }
  }
}

.color-blue {
  .icon-row-icon,
  .icon-row-text p {
    color: $color-schwind-blue;
  }
}

.color-lightblue {
  .icon-row-icon,
  .icon-row-text p {
    color: $color-schwind-lightblue;
  }
}

.color-white {
  .icon-row-icon,
  .icon-row-text p {
    color: $color-schwind-white;
  }
}

body[data-edit] .toolbox-custom-icon-row {
  padding: 0 $general-element-spacing;

  > .row {
    margin: 0;
  }
}
