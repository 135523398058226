@use "sass:math";

$spacer-base-size: 1rem;

.toolbox-spacer {
  &.no-background {
    background-color: transparent;
    background-image: none;
    border: none;
  }

  > span {
    display: block;
  }

  .spacer-1-2 {
    height: math.div($spacer-base-size, 2);
  }

  .spacer-1 {
    height: $spacer-base-size;
  }

  .spacer-2 {
    height: $spacer-base-size * 2;
  }

  .spacer-4 {
    height: $spacer-base-size * 4;
  }
}
