.personcard {
  display: block;
  margin-bottom: 1rem;
  margin-right: 1rem;

  .personcard-image-column {
    vertical-align: top;
  }

  .info-section {
    width: 100%;
    padding: 1rem;

    .person-contact-data-line {
      margin-bottom: 0.1rem;
    }

    .btn {
      margin-top: 1rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
}

.container-secondary {
  .personcard {
    background-color: white;
  }
}

@include media-breakpoint-up(sm) {
  .personcard {
    display: inline-block;

    .info-section {
      width: 330px;
    }
  }

  .col-sm-6,
  .col-lg-6,
  .col-md-6,
  .col-lg-5,
  .col-md-5,
  .col-md-4,
  .col-lg-4 {
    .personcard {
      display: block;
      margin-right: 0;

      .info-section {
        width: 100%;
      }
    }

  }
}
