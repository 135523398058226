.pimcore_editable {
  &.pimcore_editable {
    &.og-description {
      min-height: 100px;
      font-size: rem-calc(14px);
    }
  }
}

// Fix for huge pages
.pimcore_area_editmode_hidden {
  display: none;
  pointer-events: none;
}

// Fix for pimcore 5.7.x with toolbox editmode buttons
.toolbox-element-edit-button {
  margin-top: 15px !important;
}

.pimcore_area_buttons.top {
  padding-bottom: 0 !important;
}

// Fix empty input height
.pimcore_areablock_dialogBox .pimcore_editable_input {
  min-height: 18px;
}
