@use "sass:color";

.pimcore_editable.btn {
  &,
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}

.btn {
  font-weight: $font-weight-medium;
  &.disabled {
    pointer-events:none;
  }
  &.btn-white {
    padding-left: 0;
  }
  .swi {
    font-size: 18px;
    margin-right: 8px;
  }

  &.btn-sm .swi {
    font-size: 14px;
    margin-right: 6px;
  }

  &.btn-lg .swi {
    font-size: 22px;
    margin-right: 10px;
  }

  .swi-nomargin {
    margin: 0;
  }

  &.btn-fullwidth {
    width: 100%;
  }
}

.btn:not(.pimcore_editable) {
  @include underline-from-left;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  &:before {
    height: 2px;
  }

  &.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }

  &.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
  }

  &.btn-xs {
    //No XS Button!
    @include button-size(0.1rem, 0.5rem, rem-calc(13px), 1.5, 1px);
  }

  &.btn-link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.btn-arrow {
    padding-left: 1rem;

    &:before {
      content: none;
    }

    &:after {
      @include swi-icon;
      content: $swi-chevron-right;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0.75rem;
    }

    &.arrow-right:after {
      left: auto;
      right: 0;
    }
  }

  &.btn-clear {
    border-radius: 0;
  }

  /* Constant widths */
  &.btn-cw-sm {
    width: 120px;
  }

  &.btn-slide {
    background-color: $btn-slide-bg;
    color: $btn-slide-color;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    height: 6.25rem;
    width: 1.875rem;
    font-size: 1.5rem;
    padding: 0;

    @include media-breakpoint-down(xs) {
      height: 9.25rem;
      width: 1.4rem;
    }

    &.btn-slide-primary {
      background-color: $color-schwind-lightblue;
      color: $white;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

      &:hover {
        background-color: $color-schwind-blue;
        border-color: $color-schwind-lightblue;

        &:before {
          background-color: $color-schwind-lightblue;
        }
      }
    }

    &.btn-prev {
      left: 0;
    }

    &.btn-next {
      right: 0;
    }

    .swi {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: 0;
      @include transform(translate(-50%, -50%));
    }
  }

  &.btn-load-more {
    position: relative;
    border: 1px solid $btn-loadmore-color;
    color: $btn-loadmore-color;
    padding: 0.6rem 1.75rem 0.6rem 1.75rem;
    font-size: 0.6rem;
    line-height: 2;
    background: transparent;

    &:after {
      content: "•••";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.btn-outline-input {
    border: 1px solid $input-border-color;
    background-color: $input-bg;

    &.btn-outline-input-start {
      border-right-width: 0;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }

    &.btn-outline-input-end {
      border-left-width: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }

    &:focus {
      border-left-width: $input-border-width;
      border-right-width: $input-border-width;
      border-color: $input-focus-border-color;
    }

    &.btn-outline-primary {
      &:hover {
        color: $primary;
      }

      &:active {
        color: $white;
      }
    }
  }

  &.btn-form-inline {
    width: 100%;
    height: 2.35rem;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color}:before {
    background-color: color.adjust($value, $lightness: 10%);
  }
}

.btn-primary {
  &:hover {
    background-color: $color-schwind-blue;
    border-color: $color-schwind-lightblue;

    &:before {
      background-color: $color-schwind-lightblue;
    }
  }
}

.btn-secondary,
.btn-secondary.disabled {
  background-color: $color-schwind-darkgray;

  &:hover {
    background-color: $gray-600;
    border-color: $color-schwind-lightblue;

    &:before {
      background-color: $color-schwind-lightblue;
    }
  }
}


.btn-white.black {
  color: $black;
}

.btn-light.light-blue {
  color: $color-schwind-blue;
  background-color: $color-schwind-lightgray;

  &:hover {
    background-color: $gray-200;
    border-color: $color-schwind-lightblue;

    &:before {
      background-color: $color-schwind-lightblue;
    }
  }
}

.btn-teaser {
  font-weight: $font-weight-medium;
  background-color: $color-schwind-lightgray;
  color: $color-schwind-lightblue;
  top: 50%;
  @include transform(translateY(-50%));
}

/* stylelint-disable  scss/at-extend-no-missing-placeholder */
%btn {
  @extend .btn;
}

%btn-primary {
  @extend .btn-primary;
}
/* stylelint-enable  scss/at-extend-no-missing-placeholder */
