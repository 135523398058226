.toolbox-custom-image-teaser {
  display: block;

  .teaser-image-75pct {
    max-width: 75%;
  }
  .teaser-image-50pct {
    max-width: 50%;
  }

  .top-image-teasers {
    .text-top-space {
      margin-top: $space-1;
    }

    .link-top-space {
      margin-top: $space-1;
    }
  }


  &.card-layout-1-4,
  &.card-layout-2-4 {
    vertical-align: top;
  }

  @include media-breakpoint-down(md) {
    img {
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up(sm) {
    &.card-layout-1-4 {
      display: inline-block;
      width: 47%;
      margin-right: 2%;
      border: none;
      box-sizing: border-box;
    }

    &.card-layout-2-4 {
      display: inline-block;
      width: 47%;
      margin-right: 2%;
      border: none;
      box-sizing: border-box;
    }
  }

  @include media-breakpoint-up(lg) {
    &.card-layout-1-4 {
      display: inline-block;
      width: 23%;
      margin-right: 2%;
      border: none;
      box-sizing: border-box;
    }
  }


  .stick-out-teaser {
    background: $container-primary-bg; /* Old browsers */
    background: -moz-linear-gradient(top, $container-primary-bg 0%, $container-primary-bg 20%, $container-secondary-bg 21%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $container-primary-bg 0%,$container-primary-bg 20%,$container-secondary-bg 21%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $container-primary-bg 0%,$container-primary-bg 20%,$container-secondary-bg 21%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$container-primary-bg', endColorstr='$container-secondary-bg',GradientType=0 ); /* IE6-9 */

    .stick-out-teaser-image {
      display: inline-block;
      vertical-align: top;
      width: 25%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .stick-out-teaser-text {
      display: inline-block;
      width: 74%;
      top: 25%;
      @include transform(translateY(25%));
      padding: 2em 0 4em 2em;
      margin-bottom: 2em;
    }

    @include media-breakpoint-down(md) {
      .stick-out-teaser-image {
        display: block;
        width: 100%;
      }

      .stick-out-teaser-text {
        width: 100%;
        top: 0;
        @include transform(none);
        padding: 2em 0 1em 0;
      }
    }
  }

  .image-teaser-with-lightbox {
    ul.light-box {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      img {
        cursor: pointer;
      }
    }
  }

  .image-subtitle {
    color: $color-schwind-darkgray;
  }
}

.toolbox-column {
  &.equal-height-item {
    .toolbox-custom-image-teaser {
      height: 100%;
    }
    .toolbox-snippet {
      height: 100%;

      .any-questions {
        height: 100%;
      }
    }
  }
}
